import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { isEmpty } from 'lodash';
import {
  IconVerticalThreeDots,
  InlineTextButton,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
} from '..';
import { Participant } from '../../types/apollo/generated/types.generated';
import { BundleInfo } from '../../types/models/bundle';
import { isBundleCancellable } from './manageBundlesContainerUtils';
import * as zendesk from '../../util/zendesk';
import { useShopConfigV2 } from '../../hooks/shopConfig';
import { TypographyFormat } from '../TypographyWrapper/TypographyWrapper';
import css from './ManageBundlesContainer.module.css';

interface ThreeDotsIconButtonProps {
  bundle: BundleInfo;
  participant: Participant;
  onCancelClick?: (bundle: BundleInfo) => void;
}

const ThreeDotsIconButton: FC<ThreeDotsIconButtonProps> = (props: ThreeDotsIconButtonProps) => {
  const { participant, bundle, onCancelClick } = props;
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const { shopifyDomain } = useShopConfigV2();

  const buildViewInShopifyLink = () => {
    const { shopifyOrderId } = bundle;
    if (!isEmpty(shopifyOrderId)) {
      const orderId = shopifyOrderId!.split('/').pop();
      return orderId
        ? `https://admin.shopify.com/store/${shopifyDomain}/orders/${orderId}`
        : undefined;
    }
    return undefined;
  };

  const viewInShopifyLink = buildViewInShopifyLink();

  return (
    <>
      <Menu
        className={css.menu}
        onToggleActive={(isOpen: boolean) => setIsMenuOpen(isOpen)}
        isOpen={isMenuOpen}
        useArrow={false}
        contentPosition="left"
      >
        <MenuLabel className={css.threeDotIconWrapper} ariaLabel="Order Options Menu">
          <IconVerticalThreeDots className={css.threeDotIcon} />
        </MenuLabel>

        <MenuContent className={css.menuContent}>
          <MenuItem key="help" className={css.menuItem}>
            <InlineTextButton
              typographyFormat={TypographyFormat.Default}
              className={css.menuItemText}
              onClick={() => zendesk.open()}
            >
              Help with this order
            </InlineTextButton>
          </MenuItem>
          {isBundleCancellable(participant, bundle) && onCancelClick && (
            <MenuItem key="cancel" className={css.menuItem}>
              <InlineTextButton
                typographyFormat={TypographyFormat.Default}
                className={classNames(css.menuItemText, css.cancelOrder)}
                onClick={() => onCancelClick(bundle)}
              >
                Cancel order
              </InlineTextButton>
            </MenuItem>
          )}
          {viewInShopifyLink && participant === Participant.Seller && (
            <MenuItem key="cancel" className={css.menuItem}>
              <InlineTextButton
                typographyFormat={TypographyFormat.Default}
                className={classNames(css.menuItemText)}
                onClick={() => window.open(viewInShopifyLink, '_blank')}
              >
                View order in Shopify
              </InlineTextButton>
            </MenuItem>
          )}
        </MenuContent>
      </Menu>
    </>
  );
};

export default ThreeDotsIconButton;
