import { isEmpty } from 'lodash';
import { IntlShape } from 'react-intl';
import { ItemStatus } from '@prisma/client';
import {
  BundleStatus,
  CoreListingLineItem,
  Participant,
} from '../../types/apollo/generated/types.generated';
import { BundleInfo } from '../../types/models/bundle';
import { ListingItemType } from '../../types/sharetribe/listing';
import {
  convertMarketplacePriceToShopifyCurrency,
  formatDollarAmountAsCurrency,
} from '../../util/currency';
import { getTotalPayoutValueForListings } from '../../util/listings/listing';
import { getBundleListings } from '../../util/bundles';

// Filter values for brands
export const BUNDLE_STATUS_FILTER_ALL = 'ALL';
export type StatusFilterValues = BundleStatus | typeof BUNDLE_STATUS_FILTER_ALL;
export enum FilterQueryParam {
  Filter = 'filter',
  Query = 'query',
}

export const isBundleCancellable = (participant: Participant, bundle: BundleInfo) => {
  const isSeller = participant === Participant.Seller;
  return isSeller && bundle.status === BundleStatus.Open;
};

// Get the potential payout amount for cash/credit options.
// Convert the payout credit price if the brand pays out credit in
// currency that differs from the marketplace.
export const getPayoutValues = (
  bundle: BundleInfo | undefined,
  intl: IntlShape,
  brandCountryConfig: any,
  rootState: any,
  needsVerifiedBundleItems?: boolean
): {
  cash?: string;
  credit?: string;
  creditAmount?: number;
  creditInBrandShopifyCurrency?: string;
} => {
  const lineItems = bundle?.lineItems ?? [];
  let bundleItems = bundle?.bundleItems ?? [];
  if (needsVerifiedBundleItems) {
    bundleItems = bundle?.bundleItems.filter((item) => item.status === ItemStatus.VERIFIED) || [];
  }
  const { brandHasShopifyAndTreetCurrencyMismatch, currencyConfig } = brandCountryConfig;
  const sharetribeListingIds = bundleItems?.map(
    (bundleItem) => bundleItem.listing.sharetribeListingId
  );

  // Only include line items relevant to the chosen transactions.
  const listingLineItems = lineItems
    ?.map((lineItem) => {
      if (
        lineItem?.listingLineItem?.sharetribeListingId &&
        sharetribeListingIds.includes(lineItem.listingLineItem?.sharetribeListingId)
      ) {
        return lineItem.listingLineItem;
      }
      return null;
    })
    .filter((lineItem) => !!lineItem) as CoreListingLineItem[];

  const listingsFromBundle = getBundleListings(bundle, rootState);
  const { cash, credit } = getTotalPayoutValueForListings(listingsFromBundle, listingLineItems);

  const isTradeIn =
    !isEmpty(listingsFromBundle) &&
    listingsFromBundle.every(
      (listing) => listing.attributes.publicData.listingItemType === ListingItemType.TradeIn
    );

  let creditInBrandShopifyCurrency;
  if (brandHasShopifyAndTreetCurrencyMismatch && credit) {
    creditInBrandShopifyCurrency = convertMarketplacePriceToShopifyCurrency(
      credit,
      brandCountryConfig
    );
  }

  const marketplaceCurrency = currencyConfig.currency;

  const formatNumber = (value: number, currency: string) =>
    formatDollarAmountAsCurrency(intl, value, currency);

  return {
    ...(cash && { cash: formatNumber(cash, marketplaceCurrency) }),
    // Trade Ins can have credit === 0
    ...((credit || isTradeIn) && { credit: formatNumber(credit, marketplaceCurrency) }),
    creditAmount: credit,
    ...(creditInBrandShopifyCurrency && {
      creditInBrandShopifyCurrency: formatNumber(
        creditInBrandShopifyCurrency.amount,
        creditInBrandShopifyCurrency.currency
      ),
    }),
  };
};
