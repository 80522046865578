/* eslint-disable import/prefer-default-export */
import { getDefaultShopConfig, getDefaultTradeInShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';

const finnandemmaSizeOptions = [
  'NB',
  '0-3M',
  '3-6M',
  '6-9M',
  '9-12M',
  '12-18M',
  '12-24M',
  '2T',
  '3T',
  '4T',
  '5T',
  'S',
  'M',
  'L',
  'One Size',
];

export const finnandemmaShopConfig = {
  ...getDefaultShopConfig(),
  ...getDefaultTradeInShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: finnandemmaSizeOptions }),
  ],
};
