import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import classNames from 'classnames';
import { Button, TypographyWrapper } from '../../../components';
import { useStyleType } from '../../../hooks/useStyleType';
import * as zendesk from '../../../util/zendesk';
import Content from './Content';
import GeneralInfoTitle from './GeneralInfoTitle';

import css from '../AboutPage.module.css';
import { TypographyFormat } from '../../../components/TypographyWrapper/TypographyWrapper';

const SupportTab: FC = () => {
  const { isCondensedStyle } = useStyleType();

  return (
    <>
      <GeneralInfoTitle>We’re Here To Help</GeneralInfoTitle>
      <Content>
        <Typography variant="body1">
          Our team will respond to all support messages within 48 hours.
        </Typography>
      </Content>

      <GeneralInfoTitle>Chat With Us</GeneralInfoTitle>
      <Content>
        <Typography variant="body1">
          You can chat with support anytime through the bubble in the bottom right corner on most
          pages in Treet. You can also press the button below.
        </Typography>
        <Box display={{ md: 'flex' }} mt={4}>
          <div>
            <Button
              style={{ display: 'block' }}
              onClick={() => zendesk.open()}
              className={classNames({ [css.mobileButtonCondensed]: isCondensedStyle })}
            >
              Chat With Support
            </Button>
          </div>
        </Box>
      </Content>

      <GeneralInfoTitle>Email</GeneralInfoTitle>
      <Content>
        <TypographyWrapper variant="body1">
          Email Treet support at{' '}
          <a href="mailto:support@treet.co">
            <TypographyWrapper
              variant="body1"
              format={TypographyFormat.Underlined}
              typographyOverrides={{ display: 'inline' }}
            >
              support@treet.co
            </TypographyWrapper>
          </a>
          .
        </TypographyWrapper>
      </Content>
    </>
  );
};
export default SupportTab;
