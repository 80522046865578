import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  BundlePanel,
  DynamicValueWrapper,
  IconSpinner,
  ItemizedListingBreakdown,
  LineItemBreakdown,
  TypographyWrapper,
} from '..';
import {
  BundleStatus,
  BundleType,
  Participant,
} from '../../types/apollo/generated/types.generated';
import { BundleInfo } from '../../types/models/bundle';
import { CheckoutLineItem } from '../../types/models/lineItem';
import { getPayoutValues } from './manageBundlesContainerUtils';
import { getBundleListings, isBundleTradeInPayoutFlatAmount } from '../../util/bundles';
import { ListingWithAuthorAndImages } from '../../types/sharetribe/listing';
import { useBrandCountryConfig } from '../../hooks/useCountryConfig';
import { useShopConfigV2 } from '../../hooks/shopConfig';
import { useYotpo } from '../../hooks/useYotpo';
import { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import { pluralize } from '../../util/strings';
import css from './ManageBundlesContainer.module.css';

interface IndividualBundleContainerProps {
  isLoading: boolean;
  bundle?: BundleInfo;
  otherBundlesInSameOrder?: BundleInfo[];
  participant: Participant;
  onVerifyClick?: (bundle: BundleInfo) => void;
  onDisputeClick?: (bundle: BundleInfo) => void;
  onGetPaidClick?: (bundle: BundleInfo) => void;
  onMarkAsFulfilledClick?: (bundle: BundleInfo) => void;
  onMarkAsDeliveredClick?: (bundle: BundleInfo) => void;
  onEditShippingAddressSubmit?: (values: any, addressId: string, onSuccess: () => void) => void;
}

const IndividualBundleContainer: FC<IndividualBundleContainerProps> = (props) => {
  const {
    isLoading,
    bundle,
    otherBundlesInSameOrder,
    participant,
    onVerifyClick,
    onDisputeClick,
    onGetPaidClick,
    onMarkAsFulfilledClick,
    onMarkAsDeliveredClick,
    onEditShippingAddressSubmit,
  } = props;

  const intl = useIntl();
  const brandCountryConfig = useBrandCountryConfig();
  const { additionalPayoutSettings = {} } = useShopConfigV2();
  const { doesBrandUseLoyaltyPoints } = useYotpo();
  const { creditCodeHelperText } = additionalPayoutSettings;

  const rootState = useSelector<any>((state) => state) as any;

  if (!bundle) {
    // TODO (sonia-y) - empty state/error state
    return null;
  }

  if (isLoading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        flex={1}
        py={2}
      >
        <IconSpinner />
      </Box>
    );
  }

  const isTradeInBundle = bundle?.type === BundleType.TradeIn;
  const isSales = participant === Participant.Seller;

  const payoutValues = getPayoutValues(bundle, intl, brandCountryConfig, rootState);
  const potentialCreditPayout = payoutValues.credit;
  // TODO (awu | TREET-9974) Add dynamic value wrapper
  const potentialCreditPayoutInShopifyCurrency = payoutValues.creditInBrandShopifyCurrency
    ? `, issued as est. ${payoutValues.creditInBrandShopifyCurrency}`
    : '';

  // TODO (awu | TREET-9974) Add dynamic value wrapper
  const formattedPotentialPayout = potentialCreditPayout
    ? `${potentialCreditPayout} (in brand credit${potentialCreditPayoutInShopifyCurrency})`
    : `${payoutValues.cash} (in cash)`;

  const shouldShowPotentialEarnings =
    // TODO (anniew|TREET-2534) Refactor potential earnings to use listings instead of txns
    !isTradeInBundle &&
    isSales &&
    [BundleStatus.Open, BundleStatus.Shipped, BundleStatus.Delivered].includes(bundle.status);
  const listings = getBundleListings(bundle, rootState) as ListingWithAuthorAndImages[];

  // For trade-ins, the listing author is the "customer" since the brand is the entity
  // purchasing the item (no transactions present). For marketplace, the user who is
  // purchasing the item is the "customer" (transaction present).
  const tradeInCustomerName = isSales
    ? undefined
    : listings?.[0]?.author?.attributes?.profile?.displayName;
  const marketplaceCustomerName = isSales ? bundle?.buyer?.displayName : undefined;
  const customerName = isTradeInBundle ? tradeInCustomerName : marketplaceCustomerName;

  const sellerName = listings?.[0]?.author?.attributes?.profile?.displayName;

  const shouldShowLineItemBreakdown = !isBundleTradeInPayoutFlatAmount(bundle, listings);
  const isBundleReturnable = bundle?.isReturnable || false;

  return (
    <>
      <BundlePanel
        bundle={bundle}
        otherBundlesInSameOrder={otherBundlesInSameOrder}
        customerName={customerName}
        participant={participant}
        onVerifyClick={onVerifyClick}
        onDisputeClick={onDisputeClick}
        onGetPaidClick={onGetPaidClick}
        onMarkAsFulfilledClick={onMarkAsFulfilledClick}
        onMarkAsDeliveredClick={onMarkAsDeliveredClick}
        onEditShippingAddressSubmit={onEditShippingAddressSubmit}
      />
      <ItemizedListingBreakdown
        // Buyback/Trade-in items may not immediately have transactions, so in those cases, listings
        // must be passed in directly to the itemized breakdown. These listing-based breakdowns
        // won't display data that are tied to transactions, such as canceled and disputed state
        // for line items.
        listings={listings}
        lineItems={bundle.lineItems as CheckoutLineItem[]}
        bundleItems={bundle.bundleItems}
        isBundleReturnable={isBundleReturnable}
        bundleExpirationDate={bundle.expireAt}
        title={
          <h3>
            <TypographyWrapper
              variant="h2"
              typographyOverrides={{ display: 'inline' }}
              weight={TypographyWeight.Bold}
            >
              {pluralize('Item', listings.length, true)}{' '}
            </TypographyWrapper>
            <TypographyWrapper variant="h2" typographyOverrides={{ display: 'inline' }}>
              from{' '}
              <DynamicValueWrapper>
                <TypographyWrapper variant="h2" typographyOverrides={{ display: 'inline' }}>
                  {sellerName}
                </TypographyWrapper>
              </DynamicValueWrapper>
            </TypographyWrapper>
          </h3>
        }
        referrerForLogging={isSales ? 'Manage Sales' : 'Manage Purchases'}
        participant={participant}
      />
      {/* TODO (sonia-y | TREET-628): Make sure line items showing are only for relevant people */}
      {shouldShowLineItemBreakdown && (
        <LineItemBreakdown
          lineItems={bundle.lineItems as CheckoutLineItem[]}
          refunds={bundle.refunds}
          participant={participant}
          bundleType={bundle.type as BundleType}
        />
      )}
      {shouldShowPotentialEarnings && (
        <h5 className={css.potentialPayoutText}>
          {/* Need to add DynamicValueWrapper to the text below */}
          <TypographyWrapper variant="body2">
            Get paid up to{' '}
            <DynamicValueWrapper>
              <TypographyWrapper variant="body2" typographyOverrides={{ display: 'inline' }}>
                {formattedPotentialPayout}
              </TypographyWrapper>
            </DynamicValueWrapper>
            {` ${
              doesBrandUseLoyaltyPoints ? 'in loyalty points ' : ''
            }once the buyer receives and verifies this bundle`}
            {creditCodeHelperText && potentialCreditPayout ? (
              <DynamicValueWrapper>
                <TypographyWrapper variant="body2" typographyOverrides={{ display: 'inline' }}>
                  {`. ${creditCodeHelperText}`}
                </TypographyWrapper>
              </DynamicValueWrapper>
            ) : (
              ''
            )}
          </TypographyWrapper>
        </h5>
      )}
    </>
  );
};

export default IndividualBundleContainer;
