import React, { useContext, useState } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../util/reactIntl';
import config, { defaultTreetStyles } from '../../shopConfig/config';
import { createResourceLocatorString } from '../../util/routes';
import { isStripeError } from '../../util/errors';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';
import {
  Button,
  DynamicValueWrapper,
  ExternalLink,
  FieldRadioButton,
  FieldSelect,
  Form,
  FormattedMessage,
  InlineTextButton,
  StripeBankAccountTokenInputField,
  TypographyWrapper,
} from '../../components';
import { useShopConfigV2 } from '../../hooks/shopConfig';
import { useRouteConfiguration } from '../../hooks/useRouteConfiguration';
import AppContext from '../../context/AppContext';
import { TypographyFormat } from '../../components/TypographyWrapper/TypographyWrapper';
import css from './StripeConnectAccountForm.module.css';

export const stripeCountryConfigs = (countryCode) => {
  const country = config.stripe.supportedCountries.find((c) => c.code === countryCode);

  if (!country) {
    throw new Error(`Country code not found in Stripe config ${countryCode}`);
  }
  return country;
};

const countryCurrency = (countryCode) => {
  const country = stripeCountryConfigs(countryCode);
  return country.currency;
};

const CreateStripeAccountFields = (props) => {
  const { disabled, countryLabel, showAsRequired, form, values, intl, currentUserId } = props;

  const routes = useRouteConfiguration();
  const {
    internationalConfig: { allowedOriginToDestinationCountries },
  } = useShopConfigV2();
  const { canonicalRootUrl: shopUrl } = useContext(AppContext);

  /*
  We pass some default values to Stripe when creating a new Stripe account in order to reduce couple of steps from Connect Onboarding form.
  - businessProfileURL: user's profile URL
  - businessProfileMCC: default MCC code from stripe-config.js
  - accountToken (https://stripe.com/docs/connect/account-tokens) with following information:
    * accountType: individual or business
    * tos_shown_and_accepted: true
  Only country and bank account token are mandatory values. If you decide to remove the additional default values listed here, remember to update the `createStripeAccount` function in `ducks/stripeConnectAccount.duck.js`.
  */

  const individualAccountLabel = intl.formatMessage({
    id: 'StripeConnectAccountForm.individualAccount',
  });

  const companyAccountLabel = intl.formatMessage({ id: 'StripeConnectAccountForm.companyAccount' });

  const hasBusinessURL = values && values.businessProfileURL;
  // Use user profile page as business_url on this marketplace
  // or just fake it if it's dev environment using Stripe test endpoints
  // because Stripe will not allow passing a localhost URL
  if (!hasBusinessURL && currentUserId) {
    const pathToProfilePage = (uuid) =>
      createResourceLocatorString('ProfilePage', routes, { id: uuid }, {});
    const defaultBusinessURL = `${shopUrl}${pathToProfilePage(currentUserId.uuid)}`;
    form.change('businessProfileURL', defaultBusinessURL);
  }

  const hasMCC = values && values.businessProfileMCC;
  // Use default merchant category code (MCC) from stripe-config.js
  if (!hasMCC && config.stripe.defaultMCC) {
    const defaultBusinessProfileMCC = config.stripe.defaultMCC;
    form.change('businessProfileMCC', defaultBusinessProfileMCC);
  }

  const { country } = values;
  const countryRequired = validators.required(
    intl.formatMessage({
      id: 'StripeConnectAccountForm.countryRequired',
    })
  );

  const allowedSellingLocations = Object.keys(allowedOriginToDestinationCountries);
  const canOnlySellInSingleCountry = allowedSellingLocations.length === 1;
  const supportedCountries = config.stripe.supportedCountries
    .map((c) => c.code)
    .filter((code) => allowedSellingLocations.includes(code));

  return (
    <div className={css.sectionContainer}>
      <h3 className={css.subTitle}>
        <TypographyWrapper variant="h2">
          <FormattedMessage id="StripeConnectAccountForm.accountTypeTitle" />
        </TypographyWrapper>
      </h3>
      <div className={css.radioButtonRow}>
        <FieldRadioButton
          id="individual"
          name="accountType"
          label={individualAccountLabel}
          value="individual"
          showAsRequired={showAsRequired}
        />
        <FieldRadioButton
          id="company"
          name="accountType"
          label={companyAccountLabel}
          value="company"
          showAsRequired={showAsRequired}
        />
      </div>
      <FieldSelect
        id="country"
        name="country"
        disabled={canOnlySellInSingleCountry}
        className={css.selectCountry}
        autoComplete="country"
        label={countryLabel}
        validate={countryRequired}
        value={canOnlySellInSingleCountry ? allowedSellingLocations[0] : undefined}
      >
        <option disabled value="">
          {intl.formatMessage({ id: 'StripeConnectAccountForm.countryPlaceholder' })}
        </option>
        {supportedCountries.map((c) => (
          <option key={c} value={c}>
            {intl.formatMessage({ id: `StripeConnectAccountForm.countryNames.${c}` })}
          </option>
        ))}
      </FieldSelect>

      {country ? (
        <StripeBankAccountTokenInputField
          className={css.bankDetailsStripeField}
          disabled={disabled}
          name="bankAccountToken"
          formName="StripeConnectAccountForm"
          country={country}
          currency={countryCurrency(country)}
          validate={validators.required(' ')}
        />
      ) : null}
    </div>
  );
};

const UpdateStripeAccountFields = (props) => {
  const {
    disabled,
    countryLabel,
    savedCountry,
    showCardUpdateInput,
    submitInProgress,
    setShowCardUpdateInput,
    stripeBankAccountLastDigits,
  } = props;

  return (
    <div className={css.savedInformation}>
      <h3 className={css.accountInformationTitle}>
        <TypographyWrapper variant="h2">{countryLabel}</TypographyWrapper>
      </h3>
      <div className={css.savedCountry}>
        <TypographyWrapper variant="body1">
          <FormattedMessage id={`StripeConnectAccountForm.countryNames.${savedCountry}`} />
        </TypographyWrapper>
      </div>
      <h3 className={css.accountInformationTitle}>
        <TypographyWrapper variant="h2">
          <FormattedMessage id="StripeConnectAccountForm.bankAccountLabel" />
        </TypographyWrapper>
      </h3>

      {/* eslint-disable-next-line no-nested-ternary */}
      {showCardUpdateInput && savedCountry ? (
        <StripeBankAccountTokenInputField
          className={css.bankDetailsStripeField}
          disabled={disabled}
          name="bankAccountToken"
          formName="StripeConnectAccountForm"
          country={savedCountry}
          currency={countryCurrency(savedCountry)}
          validate={validators.required(' ')}
        />
      ) : !submitInProgress ? (
        <InlineTextButton
          typographyFormat={TypographyFormat.Default}
          className={css.savedBankAccount}
          onClick={() => setShowCardUpdateInput(true)}
        >
          ••••••••••••••••••••••••{' '}
          <DynamicValueWrapper>
            <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
              {stripeBankAccountLastDigits}
            </TypographyWrapper>
          </DynamicValueWrapper>
        </InlineTextButton>
      ) : null}
    </div>
  );
};

const ErrorWrapper = (props) => {
  const { children } = props;
  return (
    <div className={css.Wrapper}>
      <TypographyWrapper
        variant="body1"
        typographyOverrides={{ style: { color: defaultTreetStyles.red80 } }}
      >
        {children}
      </TypographyWrapper>
    </div>
  );
};

const ErrorsMaybe = (props) => {
  const { stripeAccountError, stripeAccountLinkError } = props;
  return isStripeError(stripeAccountError) ? (
    <ErrorWrapper>
      <FormattedMessage
        id="StripeConnectAccountForm.createStripeAccountFailedWithStripeError"
        values={{ stripeMessage: stripeAccountError.apiErrors[0].meta.stripeMessage }}
      />
    </ErrorWrapper>
  ) : stripeAccountError ? (
    <ErrorWrapper>
      <FormattedMessage id="StripeConnectAccountForm.createStripeAccountFailed" />
    </ErrorWrapper>
  ) : stripeAccountLinkError ? (
    <ErrorWrapper>
      <FormattedMessage id="StripeConnectAccountForm.createStripeAccountLinkFailed" />
    </ErrorWrapper>
  ) : null;
};

const StripeConnectAccountFormComponent = (props) => {
  const { onSubmit, ...restOfProps } = props;

  const {
    internationalConfig: { allowedOriginToDestinationCountries },
  } = useShopConfigV2();
  const [showCardUpdateInput, setShowCardUpdateInput] = useState(false);

  const isUpdate = props.stripeConnected;
  const allowedSellingLocations = Object.keys(allowedOriginToDestinationCountries);
  const canOnlySellInSingleCountry = allowedSellingLocations.length === 1;

  return (
    <FinalForm
      {...restOfProps}
      initialValues={
        canOnlySellInSingleCountry ? { country: allowedSellingLocations[0] } : undefined
      }
      onSubmit={(values) => onSubmit(values, isUpdate)}
      mutators={{
        ...arrayMutators,
      }}
      render={(fieldRenderProps) => {
        const {
          className,
          children,
          stripeAccountError,
          stripeAccountLinkError,
          disabled,
          handleSubmit,
          inProgress,
          intl,
          invalid,
          pristine,
          ready,
          savedCountry,
          stripeAccountFetched,
          stripeBankAccountLastDigits,
          submitButtonText,
          form,
          values,
          stripeConnected,
          currentUser,
        } = fieldRenderProps;

        const accountDataLoaded = stripeConnected && stripeAccountFetched && savedCountry;
        const submitInProgress = inProgress;
        const submitDisabled = pristine || invalid || disabled || submitInProgress;

        const handleFormSubmit = (event) => {
          // Close the bank account form when clicking "save details"
          setShowCardUpdateInput(false);
          handleSubmit(event);
        };

        const countryLabel = intl.formatMessage({ id: 'StripeConnectAccountForm.countryLabel' });
        const classes = classNames(css.root, className, {
          [css.disabled]: disabled,
        });

        const showAsRequired = pristine;

        const currentUserId = currentUser ? currentUser.id : null;
        const supportedCountries = config.stripe.supportedCountries
          .map((c) => c.code)
          .filter((code) => allowedSellingLocations.includes(code));

        // If the user doesn't have Stripe connected account,
        // show fields for country and bank account.
        // Otherwise, show only possibility the edit bank account
        // because Stripe doesn't allow user to change the country
        const stripeAccountFields = !stripeConnected ? (
          <CreateStripeAccountFields
            stripeConnected={stripeConnected}
            disabled={disabled}
            showAsRequired={showAsRequired}
            countryLabel={countryLabel}
            supportedCountries={supportedCountries}
            currentUserId={currentUserId}
            form={form}
            values={values}
            intl={intl}
          />
        ) : (
          <UpdateStripeAccountFields
            disabled={disabled}
            countryLabel={countryLabel}
            savedCountry={savedCountry}
            stripeBankAccountLastDigits={stripeBankAccountLastDigits}
            showCardUpdateInput={showCardUpdateInput}
            values={values}
            submitInProgress={submitInProgress}
            setShowCardUpdateInput={setShowCardUpdateInput}
            intl={intl}
          />
        );

        const stripeConnectedAccountTermsLink = (
          <ExternalLink href="https://stripe.com/connect-account/legal">
            <TypographyWrapper variant="body2" format={TypographyFormat.Underlined}>
              <FormattedMessage id="StripeConnectAccountForm.stripeConnectedAccountTermsLink" />
            </TypographyWrapper>
          </ExternalLink>
        );

        // Don't show the submit button while fetching the Stripe account data
        const submitButtonMaybe =
          !stripeConnected || accountDataLoaded ? (
            <>
              <p className={css.termsText}>
                <TypographyWrapper
                  variant="body2"
                  typographyOverrides={{ style: { color: defaultTreetStyles.gray60 } }}
                >
                  <FormattedMessage
                    id="StripeConnectAccountForm.stripeToSText"
                    values={{ stripeConnectedAccountTermsLink }}
                  />
                </TypographyWrapper>
              </p>

              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={ready}
              >
                {submitButtonText || (
                  <FormattedMessage id="StripeConnectAccountForm.submitButtonText" />
                )}
              </Button>
            </>
          ) : null;

        // If the Stripe publishable key is not set up, don't show the form
        return config.stripe.publishableKey ? (
          <Form className={classes} onSubmit={handleFormSubmit}>
            {!stripeConnected || accountDataLoaded ? (
              stripeAccountFields
            ) : (
              <div className={css.savedInformation}>
                <TypographyWrapper variant="body1">
                  <FormattedMessage id="StripeConnectAccountForm.loadingStripeAccountData" />
                </TypographyWrapper>
              </div>
            )}

            <ErrorsMaybe
              stripeAccountError={stripeAccountError}
              stripeAccountLinkError={stripeAccountLinkError}
            />

            {children}

            {submitButtonMaybe}
          </Form>
        ) : (
          <div className={css.missingStripeKey}>
            <FormattedMessage id="StripeConnectAccountForm.missingStripeKey" />
          </div>
        );
      }}
    />
  );
};

StripeConnectAccountFormComponent.defaultProps = {
  className: null,
  currentUser: null,
  stripeAccountError: null,
  disabled: false,
  inProgress: false,
  ready: false,
  savedCountry: null,
  stripeBankAccountLastDigits: null,
  submitButtonText: null,
  fieldRenderProps: null,
};

StripeConnectAccountFormComponent.propTypes = {
  currentUser: propTypes.currentUser,
  className: string,
  stripeAccountError: object,
  disabled: bool,
  inProgress: bool,
  ready: bool,
  savedCountry: string,
  stripeBankAccountLastDigits: string,
  stripeAccountFetched: bool.isRequired,
  submitButtonText: string,
  fieldRenderProps: shape({
    handleSubmit: func,
    invalid: bool,
    pristine: bool,
    values: object,
  }),

  // from injectIntl
  intl: intlShape.isRequired,
};

const StripeConnectAccountForm = compose(injectIntl)(StripeConnectAccountFormComponent);

export default StripeConnectAccountForm;
