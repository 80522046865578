import { ApolloClient, InMemoryCache } from '@apollo/client';
import { offsetLimitPagination, relayStylePagination } from '@apollo/client/utilities';
import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import fetch from 'node-fetch';
import { isFunction } from 'lodash';
import { getSubdomain, isUsingDevApiServer } from './util/envHelpers';
import * as log from './util/log';
import { SHOPIFY_GRAPHQL_CLIENT_NAME } from './util/shopifyHelpers';
import { getUrlSearchParams } from './util/urlHelpers';

// https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
const retryLink = (path) =>
  new RetryLink({
    delay: {
      // ms to wait before attempting first retry.
      // wait longer for shopify queries to account for rate limiting
      initial: path.includes('shopify') ? 1000 : 500,
      jitter: true,
    },
    attempts: {
      max: 3,
      retryIf: (error, operation) => {
        const shouldRetry = !!error;

        if (shouldRetry) {
          console.warn(
            `[Network Error Retry]: ${error.message}, GraphQL Operation: ${operation.operationName}, Path: ${path}`
          );
        }

        return shouldRetry;
      },
    },
  });

const getGraphQLUri = (path) => {
  // In development, the dev API server is running in a different port
  if (isUsingDevApiServer()) {
    // Since this is dev, the subdomain is the Treet ID
    const treetId = getSubdomain();
    const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
    return `http://${treetId}.localhost:${port}${path}`;
  }

  // Otherwise, use the same domain and port as the frontend
  return path;
};

// Apollo Link concats all of the links. We need to clear the authorization header here
// because it messes with the basic auth for the subdomans.
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});
const getGraphQLLink = (path) => {
  const urlSearchParams = getUrlSearchParams();
  const isPreview = urlSearchParams.has('preview');
  const pathWithPreview = isPreview ? `${path}?preview=true` : path;

  const link = ApolloLink.from([
    onError(({ graphQLErrors, networkError, operation }) => {
      const { operationName, variables } = operation;
      if (Array.isArray(graphQLErrors)) {
        log.error(
          new Error(`GRAPHQL Error for Operation: ${operation.operationName}`),
          'graphql-error',
          {
            graphQLErrors,
            operationName,
            variables,
            path,
          }
        );
        graphQLErrors.map(({ message, locations, path: errorPath }) =>
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${errorPath}, Operation: ${operation.operationName}`
          )
        );
      }
      if (networkError) {
        log.error(networkError, 'graphql-network-error', {
          operationName,
          variables,
          path,
        });
        console.error(
          `[Network error]: ${networkError}, GraphQL Operation: ${operation.operationName}, Path: ${path}`
        );
      }
    }),
    retryLink(pathWithPreview),
    new HttpLink({
      uri: getGraphQLUri(pathWithPreview),
      credentials: 'include',
      // needs to explicitly pass this in
      fetch,
    }),
  ]);
  return authLink.concat(link);
};

const shopifyLink = getGraphQLLink('/shopify/graphql');
const link = getGraphQLLink('/graphql');

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        orders: ((treetPolicy, shopifyPolicy, shopifyAlias) => ({
          keyArgs: (args, context) => {
            const isShopify = context.field.alias?.value === shopifyAlias;
            const keyArgs = isShopify ? shopifyPolicy.keyArgs : treetPolicy.keyArgs;
            return isFunction(keyArgs) ? keyArgs(args, context) : keyArgs;
          },
          read: (existing, options) => {
            const isShopify = options.field.alias?.value === shopifyAlias;
            const readFn = isShopify ? shopifyPolicy.read : treetPolicy.read;
            return readFn ? readFn(existing, options) : existing;
          },
          merge: (existing, incoming, options) => {
            const isShopify = options.field.alias?.value === shopifyAlias;
            const mergeFn = isShopify ? shopifyPolicy.merge : treetPolicy.merge;
            return mergeFn ? mergeFn(existing, incoming, options) : incoming;
          },
        }))(
          // Below cache identifiers need to match the query args for the 'orders' query in
          // server/graphq/schema.ts
          offsetLimitPagination(['sharetribeBuyerId', 'shopName', 'bundleStatus', 'searchQuery']),
          relayStylePagination(['query']),
          'shopifyOrders'
        ),
        bundles: offsetLimitPagination([
          // Below cache identifiers need to match the query args for the 'bundles' query in
          // server/graphq/schema.ts
          'sharetribeSellerId',
          'shopName',
          'status',
          'sharetribeListingIds',
          'searchQuery',
        ]),
        brandReports: {
          keyArgs: ['id'],
          merge: (existing, incoming, { readField }) => {
            const merged = { ...existing };
            incoming.forEach((item) => {
              merged[readField('id', item)] = item;
            });
            return merged;
          },
          read: (existing) => existing && Object.values(existing),
        },
        favoritedItems: {
          merge: (existing, incoming) => incoming,
        },
        cartItems: {
          merge: (existing, incoming) => incoming,
        },
      },
    },
  },
});

export const apolloClient = new ApolloClient({
  cache,
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === SHOPIFY_GRAPHQL_CLIENT_NAME,
    shopifyLink, // <= apollo will send here if clientName indicates it's for shopify queries
    link // <= otherwise will send here
  ),
});
