import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getSizeFilterConfig } from '../filters/size';
import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getDefaultShopConfig, getDefaultTradeInShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';

export const burjushoesSizes = [
  '5',
  '5.5',
  '6',
  '6.5',
  '7',
  '7.5',
  '8',
  '8.5',
  '9',
  '9.5',
  '10',
  '10.5',
  '11',
  '12',
  '13',
  '14',
  '15',
];

export const burjushoesConditionOptions = [
  {
    // We are keeping the same as clothes, because we have logic that says if its NWT, then X & Y
    key: Conditions.NewWithTags,
    label: 'New With Shoe Bag',
    description:
      'Your shoes have never been worn and they’re still in the original bag. For this option, you’ll need to take pictures of your shoes in the bag.',
  },
  {
    key: Conditions.NewWithoutTags,
    label: 'New Without Shoe Bag',
    description:
      'Your shoes have never been worn (other than perhaps trying it on), but you no longer have the bag. There are no quirks or issues whatsoever.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent Condition',
    description:
      'Your shoes have only been lightly used and have been extremely well maintained. There may be some small signs of wear, but nothing major.',
  },
  {
    key: Conditions.Good,
    label: 'Good Condition',
    description:
      'You’ve worn the shoes and they’re well maintained. There may be some quirks like a small stain or a scuff mark, but they’re overall in good shape. You will need to take pictures of any quirks your shoes have.',
  },
];

const categoryPercentages = {
  thighhighs: 0.19,
  'thigh high': 0.19,
  kneehighs: 0.017,
  'knee high': 0.17,
  ankleboots: 0.16,
  ankle: 0.16,
  strappyheels: 0.15,
  pumps: 0.11,
  other: 0.105,
};

const tagPercentages = {
  'Special Occasion': 0.05,
  Wedding: 0.04,
  Stiletto: 0.03,
  'Street Sole': 0.03,
  Vegan: 0.025,
  'Closed Toe': 0.025,
  Boot: 0.02,
  Boots: 0.02,
  'Thigh High': 0.02,
  'Open Toe': 0.02,
  Crystal: 0.02,
  'Knee High': 0.02,
  Knee: 0.02,
  Satin: 0.019,
  'Ballroom Dance': 0.0175,
  Lycra: 0.017,
  Kiira: 0.016,
  Ankle: 0.015,
  Platform: 0.015,
  'Lace Up': 0.015,
  Black: 0.0145,
  'Black Wet Look': 0.0145,
  'All Black': 0.0145,
  Brown: 0.013,
  Burgundy: 0.013,
  Leopard: 0.013,
  White: 0.0125,
  'White Wet Look': 0.0125,
  Red: 0.0125,
  'Rubber Sole': 0.0125,
  'Nude Barre': 0.0125,
  Nude: 0.0125,
  'Shade One': 0.0125,
  'Shade Two': 0.0125,
  'Shade Three': 0.0125,
  'Shade Four': 0.0125,
  'Shade Five': 0.0125,
  'Shade Six': 0.0125,
  'Shade Seven': 0.0125,
  'Shade Eight': 0.0125,
  Corduroy: 0.0125,
  Ivory: 0.012,
  'Black and White': 0.012,
  'Multi Color': 0.012,
  Suede: 0.01,
  Slim: 0.01,
  Cinnamon: 0.01,
  Chartreuse: 0.01,
  'Chai Wet Look': 0.01,
  'Dance Floor': 0.01,
  Dance: 0.01,
  'Short Ankle': 0.01,
  Chunky: 0.01,
  'Heeled Sandal': 0.01,
  Leather: 0.01,
  'Light Tan': 0.01,
  'Dark Tan': 0.01,
  'Medium Tan': 0.01,
  'Dark Tan Wet Look': 0.01,
  Tan: 0.01,
  'Tan Snake': 0.01,
  'True Nudes': 0.01,
  'Blind Box': 0.01,
  Denim: 0.01,
  'Rose Gold': 0.01,
  'Black Glitter': 0.01,
  Metallic: 0.01,
  Gold: 0.01,
  'Gold Glitter': 0.01,
  'Black and Tan': 0.01,
  Silver: 0.009,
  'Silver Glitter': 0.009,
  Camel: 0.009,
  Snake: 0.009,
  Sequins: 0.009,
  Bronze: 0.009,
  Taupe: 0.009,
  Blue: 0.008,
  Lavender: 0.008,
  Champaign: 0.008,
  Grey: 0.008,
  Rubber: 0.008,
  'Suede Sole': 0.0075,
  Yellow: 0.007,
  Orange: 0.007,
  Green: 0.007,
  Periwinkle: 0.007,
  Pole: 0.007,
  Pride: 0.007,
  'Tie Dye': 0.007,
  Graffiti: 0.007,
  'Flared Heel': 0.005,
  Calf: 0.005,
  'wide fit': 0.005,
  Neon: 0.005,
  Camo: 0.005,
  'Rainbow Iridescent': 0.005,
  'Light Pink': 0.005,
  'Pink Wet Look': 0.005,
  Pink: 0.005,
  Rainbow: 0.005,
  'Hot Pink': 0.005,
  'Pointed Toe': 0.003,
  Pumps: 0.002,
  Patent: 0.002,
  Floral: 0.002,
  Aqua: 0.002,
  Flower: 0.002,
  Purple: 0.002,
  Sandle: -0.005,
  Flats: -0.005,
  fishnets: -0.01,
  hosiery: -0.01,
};

export const burjushoesShopConfig = {
  ...getDefaultShopConfig(),
  ...getDefaultTradeInShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: burjushoesConditionOptions }),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: burjushoesSizes }),
  ],
  resaleValueConfig: {
    categoryPercentages,
    tagPercentages,
  },
};
