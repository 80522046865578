import Box from '@material-ui/core/Box';
import React, { FC, ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { IconSpinner, ListingCardPreview, Overlay } from '..';
import { logSelectItem } from '../../analytics/pixelAnalytics';
import { ListingWithImages } from '../../types/sharetribe/listing';
import { resetActiveModal } from '../../ducks/modal.duck';
import css from './Bundle.module.css';

interface BundleProps {
  listings: ListingWithImages[];
  referrerForLogging: string;
  isEditing?: boolean;
  isLoading?: boolean;
  heading?: ReactElement;
  actionButton?: ReactElement;
  listingsInlineButton?: ReactElement;
  allowListingRedirect?: boolean;
  handleRemoveListings?: (listingIds: string[]) => void;
}

const Bundle: FC<BundleProps> = ({
  listings,
  referrerForLogging,
  isEditing = false,
  isLoading = false,
  heading,
  actionButton,
  listingsInlineButton,
  allowListingRedirect = true,
  handleRemoveListings = () => {},
}: BundleProps) => {
  const dispatch = useDispatch();
  return (
    <Box display="flex" flexDirection="column" p="20px" className={css.root}>
      {heading}
      <Box display="flex" flexDirection="row" className={css.listingImages}>
        {listings.map((l) => (
          <ListingCardPreview
            allowListingRedirect={allowListingRedirect}
            isEditing={isEditing}
            listing={l}
            onRemoveClick={() => handleRemoveListings([l.id.uuid])}
            key={l.id.uuid}
            onClick={() => {
              logSelectItem(l, referrerForLogging);
              dispatch(resetActiveModal());
            }}
          />
        ))}
        {listingsInlineButton}
      </Box>
      {isLoading && (
        <Overlay>
          <IconSpinner />
        </Overlay>
      )}
      {actionButton}
    </Box>
  );
};

export default Bundle;
