/* eslint-disable no-underscore-dangle */
/* eslint-disable global-require */
/**
 * This is the main entrypoint file for the application.
 *
 * When loaded in the client side, the application is rendered in the
 * #root element.
 *
 * When the bundle created from this file is imported in the server
 * side, the exported `renderApp` function can be used for server side
 * rendering.
 *
 * Note that this file is required for the build process.
 */

// React 16 depends on the collection types Map and Set, as well as
// requestAnimationFrame.
// https://reactjs.org/docs/javascript-environment-requirements.html
import 'core-js/features/map';
import 'core-js/features/set';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { loadableReady } from '@loadable/component';
import MobileDetect from 'mobile-detect';
import builder from '@builder.io/react';
import { createInstance, types as sdkTypes } from './util/sdkLoader';
import { ClientApp, RedirectClientApp, renderApp } from './app';
import configureStore from './store';
import { getBasePath, matchPathname } from './util/routes';
import { fetchGeneralShopConfig, fetchShopConfig } from './ducks/initial.duck';
import * as sample from './util/sample';
import * as apiUtils from './util/api';
import sharetribeConfig from './sharetribeConfig';
import config from './shopConfig/config';
import { authInfo } from './ducks/Auth.duck';
import { fetchCurrentUser } from './ducks/user.duck';
import routeConfiguration, { PAGE_TO_PATH, PATH_TO_BUILDER_SECTIONS } from './routeConfiguration';
import { getCurrencyConfig } from './hooks/useCountryConfig';
import { getProductDisplayTitle, getStockImages } from './util/shopifyHelpers';
import * as log from './util/log';
import * as intercom from './util/intercom';
import './marketplaceIndex.css';
import {
  getActivePromoConfigs,
  getEnabledCustomerExperiences,
  getShopConfig,
} from './shopConfig/configHelper';
import * as datadogRum from './util/datadogRum';
import * as datadogLogs from './util/datadogLogs';
import { fetchTreetShopConfig } from './containers/TreetShopLandingPage/TreetShopLandingPage.duck';
import { getTradeInCreditPrice } from './containers/EditListingPage/EditListingPage.utils';
import { getBundleDisplayNumber } from './util/bundles';
import * as currencyHelpers from './util/currency';
import * as lineItemHelpers from './util/lineItems';
import { createCustomTokenStore } from './util/tokenStore/tokenStore';
import { isDev, isTest, isUsingDevApiServer } from './util/envHelpers';
import { getUrlSearchParams } from './util/urlHelpers';
import {
  calculateListingPayoutUpdateFields,
  convertPGListingToSTListing,
  convertSTListingToPGListing,
  getOriginalShopifyProductPrice,
  getSizeVariantValue,
  shouldAutoApproveListing,
} from './util/listings/listing';
import * as listingImageHelpers from './util/listings/listingImages';
import { setInitialHeapEventProperties } from './util/heap';
import {
  CATEGORY_TO_DEFAULT_NOTE,
  ListingFeedbackCategory,
} from './components/ListingFeedbackModal/ListingFeedback.utils';
import { BuilderSections } from './util/builder';
import { getPrivateShopConfig } from './shopConfig/configV2';
import * as returnHelpers from './util/returns';
import { getOptimizedImageSrcs } from './util/contentful';
import { getCountryObjectFromCode } from './util/countryCodes';

// If webapp is using SSL (i.e. it's behind 'https' protocol)
const usingSSL = process.env.REACT_APP_SHARETRIBE_USING_SSL === 'true';

function getShopConfigV2Style(property, shopConfigV2, isMobile) {
  if (!shopConfigV2) return null;

  const loginImageUrl = shopConfigV2?.images?.loginImage?.url;
  const missionImageUrl = shopConfigV2?.images?.missionImage?.url;
  const backgroundImageUrl = loginImageUrl || missionImageUrl;
  const footerImageDefaultUrl = shopConfigV2?.images?.footerImage?.url;
  const footerImageMobileUrl = shopConfigV2?.images?.footerImageMobile?.url;
  const disableFooterTint = shopConfigV2?.css?.disableFooterTint;
  const footerImageUrl = (isMobile && footerImageMobileUrl) || footerImageDefaultUrl;
  const linearGradient = disableFooterTint
    ? 'linear-gradient(-46deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))'
    : 'linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3))';

  // Special Case images -- we want these particular images to load quickly so
  // they are using backgroundImage, and we want to add a gradient on top
  if (
    (property === 'backgroundImageUrl' || property === 'backgroundImageUrlSmall') &&
    backgroundImageUrl
  ) {
    return `${linearGradient}, url('${backgroundImageUrl}')`;
  }
  if ((property === 'footerImageUrl' || property === 'footerImageUrlSmall') && footerImageUrl) {
    const optimizedImageSrcs = getOptimizedImageSrcs(footerImageUrl, isMobile ? '600' : undefined);
    const optimizedFooterUrl = optimizedImageSrcs.at(-1);
    return `${linearGradient}, url('${optimizedFooterUrl}')`;
  }
  return null;
}

// Keep in sync with server/utils/font.ts
const getFormatFromFileType = (url) => {
  const fontFileType = url?.split('.')?.pop()?.toLowerCase();
  let fileFormat;
  switch (fontFileType) {
    case 'otf':
      fileFormat = 'opentype';
      break;
    case 'ttf':
      fileFormat = 'truetype';
      break;
    case 'woff2':
    case 'woff':
    default:
      fileFormat = fontFileType;
  }

  return fileFormat;
};

// Keep this logic in sync with server/renderer.js (getDynmaicCssStyle)
function setupDynamicCss(treetId, shopConfigV2, isMobile) {
  const shopConfig = getShopConfig(treetId, shopConfigV2);
  const { styles, css } = shopConfig;
  if (styles) {
    Object.entries(styles).forEach(([property, value]) => {
      const shopConfigV2Value = getShopConfigV2Style(property, shopConfigV2, isMobile);
      document.documentElement.style.setProperty(`--${property}`, shopConfigV2Value || value);
    });
  }

  if (css?.fontLinks) {
    document.head.innerHTML += css.fontLinks;
  }

  if (css?.brandFontsCollection?.items) {
    const fontDefinitions = [];
    // Keep in sync with server/utils/font.ts (buildFontScript)
    css.brandFontsCollection.items.forEach((fontFamily) => {
      const { fontFamilyName, fontWeight, fontStyle, fontFile } = fontFamily;
      const fontWeightString = fontWeight ? `font-weight:${fontWeight};` : '';
      const fontStyleString = fontStyle ? `font-style:${fontStyle};` : '';
      fontDefinitions.push(
        `@font-face{font-family:${fontFamilyName};${fontWeightString}${fontStyleString}src: url(${
          fontFile.url
        }) format('${getFormatFromFileType(fontFile.url)}')}`
      );
    });
    document.head.innerHTML += `<style>${fontDefinitions.join('')}</style>`;
  }
}

const render = (store, shouldHydrate) => {
  // If the server already loaded the auth information, render the app
  // immediately. Otherwise wait for the flag to be loaded and render
  // when auth information is present.

  const { treetId, userAgent } = store.getState().initial;
  const { authInfoLoaded } = store.getState().Auth;
  const authCall = authInfoLoaded ? Promise.resolve({}) : store.dispatch(authInfo());

  const shopConfigV2Loaded = store.getState().initial.shopConfig;
  const generalShopConfigLoaded = store.getState().initial.generalShopConfig;

  const treetShopConfigV2Loaded = store.getState().TreetShopLandingPage.treetShopConfig;

  const isTreetShop = treetId === 'treet';

  let configCall;
  if (isTreetShop) {
    configCall = treetShopConfigV2Loaded
      ? Promise.resolve({})
      : store.dispatch(fetchTreetShopConfig());
  } else {
    configCall = shopConfigV2Loaded ? Promise.resolve({}) : store.dispatch(fetchShopConfig());
  }

  if (generalShopConfigLoaded == null) {
    // explicitly check for null since it may be an empty {} if it errored out
    store.dispatch(fetchGeneralShopConfig());
  }

  Promise.all([authCall, configCall])
    .then(() => {
      // eslint-disable-next-line no-shadow
      const shopConfigV2 = store.getState().initial.shopConfig;
      if (isUsingDevApiServer()) {
        const md = new MobileDetect(userAgent);
        const isMobile = !!md.mobile();
        // Need to set up the html css styling client-side if it's not ssr
        setupDynamicCss(treetId, shopConfigV2, isMobile);
      }

      // Set heap event properties from Shop Config values.
      setInitialHeapEventProperties(shopConfigV2);

      store.dispatch(fetchCurrentUser());
      return loadableReady();
    })
    .then(() => {
      if (shouldHydrate) {
        ReactDOM.hydrate(<ClientApp store={store} />, document.getElementById('root'));
      } else {
        ReactDOM.render(<ClientApp store={store} />, document.getElementById('root'));
      }
    })
    .catch((e) => {
      log.error(e, 'browser-side-render-failed');
    });
};

// This functions uses retry logic to fetch builder content for a section
// It uses a timeout for the first two retries and then retries the request
// until it either succeeds or the max retries are reached
const fetchBuilderContentForSection = async (
  builderSection,
  builderSectionId,
  isPreview,
  retryCount = 0,
  maxRetries = 3
) => {
  try {
    const builderResponse = await (retryCount === maxRetries - 1
      ? builder
          .get(builderSection, {
            ...(isPreview && { options: { includeUnpublished: true } }),
            query: { id: builderSectionId },
          })
          .promise()
      : Promise.race([
          builder
            .get(builderSection, {
              ...(isPreview && { options: { includeUnpublished: true } }),
              query: { id: builderSectionId },
            })
            .promise(),
          new Promise((_, reject) => setTimeout(() => reject(new Error('Request timeout')), 2000)),
        ]));

    return builderResponse;
  } catch (error) {
    if (retryCount < maxRetries - 1) {
      // See how often builder content fetch is being retried.
      // Mute on Sentry if noisy.
      log.error(error, 'retry-builder-content-fetch', { retryCount });
      return fetchBuilderContentForSection(
        builderSection,
        builderSectionId,
        isPreview,
        retryCount + 1,
        maxRetries
      );
    }
    log.error(error, 'builder-content-fetch-failed');
    throw error;
  }
};

const getBuilderContentForStore = async (treetId, pathname, isPreview) => {
  if (treetId === 'treet') return {};

  const shopConfig = getPrivateShopConfig(treetId);
  const basePath = getBasePath(pathname);

  const builderSectionsForPage = [
    ...(PATH_TO_BUILDER_SECTIONS[basePath] || []),
    ...(basePath === PAGE_TO_PATH.AdminBasePage
      ? []
      : [BuilderSections.TopbarContent, BuilderSections.TopbarMobileContent]),
  ];

  const builderContent = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const builderSection of builderSectionsForPage) {
    const builderSectionId = shopConfig?.builderConfig?.sections?.[builderSection];
    if (builderSectionId) {
      // eslint-disable-next-line no-await-in-loop
      const builderSectionContent = await fetchBuilderContentForSection(
        builderSection,
        builderSectionId,
        isPreview
      );
      builderContent[builderSectionId] = builderSectionContent;
    }
  }

  return builderContent;
};

const initializeStore = async () => {
  if (typeof window === 'undefined') return;

  // Make sure to keep this in sync with the definition in config.js
  const {
    id: shopId,
    treetId,
    canonicalRootUrl,
    defaultCurrency,
    primaryLocale,
  } = await apiUtils.getShop();
  const { origin } = window.location;
  const { userAgent } = window.navigator;
  const currencyConversionRates = await apiUtils.fetchConversionRates();

  // Set up intercom
  intercom.setup(treetId);

  const { pathname } = window.location;
  const urlSearchParams = getUrlSearchParams();
  const isPreview = urlSearchParams.has('preview');
  const builderContent = await getBuilderContentForStore(treetId, pathname, isPreview);

  // eslint-disable-next-line no-underscore-dangle
  const preloadedState = window.__PRELOADED_STATE__ || '{}';
  const initialState = {
    ...JSON.parse(preloadedState, sdkTypes.reviver),
    initial: {
      treetId,
      shopId,
      canonicalRootUrl,
      origin,
      userAgent,
      initialSearchParams: getUrlSearchParams(),
      defaultCurrency,
      primaryLocale,
      currencyConversionRates,
      builderContent,
    },
  };

  const tokenStore = createCustomTokenStore({
    clientId: sharetribeConfig.sdk.clientId,
    secure: usingSSL,
  });

  const baseUrl = sharetribeConfig.sdk.baseUrl ? { baseUrl: sharetribeConfig.sdk.baseUrl } : {};
  const sdk = createInstance({
    transitVerbose: sharetribeConfig.sdk.transitVerbose,
    clientId: sharetribeConfig.sdk.clientId,
    secure: usingSSL,
    typeHandlers: apiUtils.typeHandlers,
    tokenStore,
    ...baseUrl,
  });

  const store = configureStore(initialState, sdk);

  require('./util/polyfills');
  render(store, !!window.__PRELOADED_STATE__);

  if (isDev) {
    // Expose stuff for the browser REPL
    window.app = {
      sharetribeConfig,
      config,
      sdk,
      sdkTypes,
      store,
      sample,
      routeConfiguration: routeConfiguration(treetId),
    };
  }
};

// If we're in a browser already, render the client application.
if (typeof window !== 'undefined') {
  // set up logger with Sentry DSN client key and environment
  log.setup();

  if (window.location.pathname === '/redirect') {
    const emptyStore = configureStore();
    ReactDOM.render(<RedirectClientApp store={emptyStore} />, document.getElementById('root'));
  } else {
    if (!isDev && !isTest) {
      // set up Datadog RUM
      datadogRum.setup();

      // set up Datadog Browser Logging
      datadogLogs.setup();
    }

    initializeStore();
  }
}

// Export the function for server side rendering.
export default renderApp;

// exporting matchPathname and configureStore for server side rendering.
// matchPathname helps to figure out which route is called and if it has
// preloading needs configureStore is used for creating initial store state
// for Redux after preloading
export {
  matchPathname,
  fetchShopConfig,
  fetchTreetShopConfig,
  configureStore,
  routeConfiguration,
  getEnabledCustomerExperiences,
  getTradeInCreditPrice,
  getActivePromoConfigs,
  getShopConfig,
  getBuilderContentForStore,
  config,
  getCurrencyConfig,
  getCountryObjectFromCode,
  getBundleDisplayNumber,
  getProductDisplayTitle,
  currencyHelpers,
  lineItemHelpers,
  getOriginalShopifyProductPrice,
  calculateListingPayoutUpdateFields,
  getSizeVariantValue,
  shouldAutoApproveListing,
  getStockImages,
  listingImageHelpers,
  ListingFeedbackCategory,
  CATEGORY_TO_DEFAULT_NOTE,
  convertPGListingToSTListing,
  convertSTListingToPGListing,
  returnHelpers,
};
