import intersection from 'lodash/intersection';
import routeConfiguration from '../../routeConfiguration';
import config from '../../shopConfig/config';
import { Feature, isFeatureEnabled } from '../../util/featureFlags';
import { cleanSearchParamsForFrenzy } from '../../util/frenzyHelpers';
import { trackFilter } from '../../util/heap';
import { createResourceLocatorString } from '../../util/routes';
import { cleanSearchFromConflictingParams, parseSelectFilterOptions } from '../../util/search';
import { stringify } from '../../util/urlHelpers';

import { FrenzyApiModes } from '../../types/frenzy/query';
import { NO_SCROLL } from '../../Routes';

const flatten = (acc, val) => acc.concat(val);

/**
 * Validates a filter search param agains a filters configuration.
 *
 * All invalid param names and values are dropped
 *
 * @param {String} queryParamName Search parameter name
 * @param {Object} paramValue Search parameter value
 * @param {Object} filters Filters configuration
 */
export const validURLParamForExtendedData = (queryParamName, paramValueRaw, filters) => {
  // Resolve configuration for this filter
  const filterConfig = filters.find((f) => {
    const isArray = Array.isArray(f.queryParamNames);
    return isArray
      ? f.queryParamNames.includes(queryParamName)
      : f.queryParamNames === queryParamName;
  });

  const paramValue = paramValueRaw.toString();

  if (filterConfig) {
    if (['SelectSingleFilter', 'SelectMultipleFilter'].includes(filterConfig.type)) {
      // Pick valid select options only
      const allowedValues = filterConfig.config.options.map((o) => o.key);
      const valueArray = parseSelectFilterOptions(paramValue);
      const validValues = intersection(valueArray, allowedValues).join(',');

      return validValues.length > 0 ? { [queryParamName]: validValues } : {};
    }
    if (filterConfig) {
      // Generic filter - remove empty params
      return paramValue.length > 0 ? { [queryParamName]: paramValue } : {};
    }
  }
  return {};
};

/**
 * Checks filter param value validity.
 *
 * Non-filter params are dropped.
 *
 * @param {Object} params Search params
 * @param {Object} filters Filters configuration
 */
export const validFilterParams = (params, filters) => {
  const filterParamNames = filters.map((f) => f.queryParamNames).reduce(flatten, []);
  const paramEntries = Object.entries(params);

  return paramEntries.reduce((validParams, entry) => {
    const [paramName, paramValue] = entry;

    return filterParamNames.includes(paramName)
      ? {
          ...validParams,
          ...validURLParamForExtendedData(paramName, paramValue, filters),
        }
      : { ...validParams };
  }, {});
};

/**
 * Checks filter param value validity.
 *
 * Non-filter params are returned as they are.
 *
 * @param {Object} params Search params
 * @param {Object} filters Filters configuration
 */
export const validURLParamsForExtendedData = (params, filters) => {
  const filterParamNames = filters.map((f) => f.queryParamNames).reduce(flatten, []);
  const paramEntries = Object.entries(params);

  return paramEntries.reduce((validParams, entry) => {
    const [paramName, paramValue] = entry;

    return filterParamNames.includes(paramName)
      ? {
          ...validParams,
          ...validURLParamForExtendedData(paramName, paramValue, filters),
        }
      : { ...validParams, [paramName]: paramValue };
  }, {});
};

// extract search parameters, including a custom URL params
// which are validated by mapping the values to marketplace custom config.
export const pickSearchParamsOnly = (params, filters, sortConfig) => {
  const { address, origin, bounds, ...rest } = params || {};
  const boundsMaybe = bounds ? { bounds } : {};
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  const filterParams = validFilterParams(rest, filters);
  const sort = rest[sortConfig.queryParamName];
  const sortMaybe = sort ? { sort } : {};

  return {
    ...boundsMaybe,
    ...originMaybe,
    ...filterParams,
    ...sortMaybe,
  };
};

export const areSearchParamsInSync = (urlQueryParams, searchParams, filterConfig, sortConfig) => {
  const urlQueryString = stringify(urlQueryParams);
  const paramsQueryString = stringify(pickSearchParamsOnly(searchParams, filterConfig, sortConfig));
  return urlQueryString === paramsQueryString;
};

/**
 * @typedef {Object} Params
 * @property {Object} urlQueryParams
 * @property {Object} currentQueryParams
 * @property {?Object} [currentUser] - The current user object, optional.
 * @property {Function} setCurrentQueryParams
 * @property {Object} sortConfig
 * @property {Object} filterConfig
 * @property {Object} history
 * @property {string} shopName
 * @property {string} treetId
 */

/**
 * @typedef {('FilterChange' | 'RawQuery')} FrenzyApiModes
 */

/**
 * Creates a handler for search changes.
 * @param {Params} params - The parameters for the function.
 * @returns {(useHistoryPush: boolean, frenzyApiMode?: FrenzyApiModes | null, shouldNotScroll?: boolean | null) => (updatedURLParams: Object) => void}
 */
export const getHandleSearchChangedValueFn =
  ({
    urlQueryParams,
    currentQueryParams,
    currentUser,
    setCurrentQueryParams,
    sortConfig,
    filterConfig,
    history,
    shopName,
    treetId,
  }) =>
  /**
   * @param {boolean} useHistoryPush - Whether to use history push.
   * @param {FrenzyApiModes | null} [frenzyApiMode=null] - The mode for Frenzy API.
   * @param {boolean | null} [shouldNotScroll=null] - Whether to disable scrolling.
   * @returns {(updatedURLParams: Object) => void} - The handler function.
   */
  (useHistoryPush, frenzyApiMode = null, shouldNotScroll = null) =>
  (updatedURLParams) => {
    const mergedQueryParams = { ...urlQueryParams, ...currentQueryParams };
    const isFrenzySearchEnabled = isFeatureEnabled(Feature.FrenzySearch, treetId, currentUser);
    let updatedCurrentQueryParams = {
      ...mergedQueryParams,
      ...updatedURLParams,
      ...(isFrenzySearchEnabled && !!frenzyApiMode && { mode: frenzyApiMode }),
    };

    if (frenzyApiMode === FrenzyApiModes.RawQuery) {
      // Do not preserve any filters for a Frenzy Raw Query search
      updatedCurrentQueryParams = cleanSearchParamsForFrenzy(updatedCurrentQueryParams);
    }
    setCurrentQueryParams(updatedCurrentQueryParams);

    // Note: This piece of code below used to be called in a callback after setState, but it shoudn't be
    // a problem even if it's not called as a callback.
    if (useHistoryPush) {
      const searchParams = updatedCurrentQueryParams;
      const search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig);

      history.push(
        createResourceLocatorString(
          'LandingPage',
          routeConfiguration(treetId),
          {},
          { ...search, [NO_SCROLL]: shouldNotScroll }
        )
      );
    }

    // Track what's being filtered to Heap
    Object.keys(updatedURLParams).forEach((key) => {
      const filteredValues = updatedURLParams[key];
      if (filteredValues) {
        const valuesToTrack = key === 'price' ? [filteredValues] : filteredValues.split(',');
        valuesToTrack.forEach((value) => trackFilter(shopName, key, value));
      }
    });
  };
