import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React, { FC } from 'react';
import { Builder } from '@builder.io/react';
import { Button, ConditionalWrapper, ExternalLink, IconComingSoon, NamedLink } from '..';
import { useShopConfig } from '../../hooks/shopConfig';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { useIsShopSideLaunched } from '../../hooks/useIsShopSideLaunched';
import { useStyleType } from '../../hooks/useStyleType';
import { HeroAction, HeroActionItem } from '../../types/shopConfig/shopConfigV2';
import { ButtonVariant } from '../Button/Button';
import MultiButtonWrapper from '../MultiButtonWrapper/MultiButtonWrapper';
import ComingSoonModal from './ComingSoonModal';

import css from './HeroActions.module.css';
import { ModalType } from '../../ducks/modal.duck';
import { useActiveModal } from '../../hooks/useActiveModal';
import { useShopCss } from '../../hooks/useShopCss';

interface ButtonProps {
  buttonVariant: ButtonVariant;
  isOnImage: boolean;
}

const ShopButton: FC<ButtonProps & { searchParams?: string }> = (props) => {
  const { buttonVariant, isOnImage, searchParams } = props;

  const isShopSideLaunched = useIsShopSideLaunched();
  const shopCss = useShopCss();

  const { primaryButton, secondaryButton } = shopCss;
  const buttonConfig = buttonVariant === ButtonVariant.Secondary ? secondaryButton : primaryButton;
  const hasTransparentBackground = buttonConfig?.backgroundColor === '#00000000';

  const {
    copy: { shopButtonText },
  } = useShopConfig();

  const { isModalOpen, openModal, closeModal } = useActiveModal(ModalType.ComingSoon);

  return (
    <>
      <ConditionalWrapper
        condition={isShopSideLaunched}
        wrapper={(children) => (
          <NamedLink
            name="LandingPage"
            style={{ textDecoration: 'none' }}
            to={{
              state: { scrollOnRender: true },
              ...(searchParams ? { search: searchParams } : { search: 'mode=raw-query' }),
            }}
          >
            {children}
          </NamedLink>
        )}
      >
        <Button
          className={classNames({ [css.buttonOnImage]: isOnImage })}
          variant={buttonVariant}
          onClick={isShopSideLaunched ? undefined : openModal}
        >
          {!isShopSideLaunched && !hasTransparentBackground && (
            <IconComingSoon
              className={css.comingSoonImg}
              color={shopCss?.comingSoonBannerColor || 'black'}
              textColor={shopCss?.comingSoonBannerFontColor}
            />
          )}
          {shopButtonText}
          {!isShopSideLaunched && hasTransparentBackground && ' (Coming Soon)'}
        </Button>
      </ConditionalWrapper>
      <ComingSoonModal isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};

const SellButton: FC<ButtonProps> = (props) => {
  const { buttonVariant, isOnImage } = props;
  const {
    copy: { sellButtonText },
  } = useShopConfig();
  const { isCondensedStyle } = useStyleType();
  const { isListTradeInOnly } = useEnabledCustomerExperiences();

  return (
    <NamedLink
      name={isListTradeInOnly ? 'ManageTradeInsPage' : 'NewListingPage'}
      style={{ textDecoration: 'none', display: 'flex', justifyContent: 'center' }}
    >
      <Button
        className={classNames({
          [css.buttonOnImage]: isOnImage,
          [css.mobileButtonCondensed]: isCondensedStyle,
        })}
        variant={buttonVariant}
      >
        {sellButtonText}
      </Button>
    </NamedLink>
  );
};

interface ExternalLinkButtonProps extends ButtonProps {
  linkConfig?: { label?: string; url?: string };
}

const ExternalLinkButton: FC<ExternalLinkButtonProps> = (props) => {
  const { buttonVariant, isOnImage, linkConfig } = props;
  const { isCondensedStyle } = useStyleType();

  if (isEmpty(linkConfig)) return null;

  return (
    <ExternalLink href={linkConfig!.url as string}>
      <Button
        className={classNames({
          [css.buttonOnImage]: isOnImage,
          [css.mobileButtonCondensed]: isCondensedStyle,
        })}
        variant={buttonVariant}
      >
        {linkConfig!.label}
      </Button>
    </ExternalLink>
  );
};

interface HeroActionsProps {
  isOnImage?: boolean;
}

const HeroActions: FC<HeroActionsProps> = (props) => {
  const { isOnImage = true } = props;
  const { externalHeroActions } = useShopConfig();
  const { allowList, isShopClosed, allowBuy } = useEnabledCustomerExperiences();

  const heroActions = [
    ...(allowBuy ? [{ type: HeroAction.Shop }] : []),
    ...(allowList ? [{ type: HeroAction.Sell }] : []),
    ...(externalHeroActions || []),
  ];

  if (isShopClosed) return null;

  const buttonVariant = isOnImage ? ButtonVariant.Secondary : ButtonVariant.Primary;

  const actions = {
    [HeroAction.Shop]: <ShopButton buttonVariant={buttonVariant} isOnImage={isOnImage} />,
    [HeroAction.Sell]: <SellButton buttonVariant={buttonVariant} isOnImage={isOnImage} />,
    [HeroAction.ExternalLink]: (
      <ExternalLinkButton
        buttonVariant={buttonVariant}
        isOnImage={isOnImage}
        linkConfig={
          externalHeroActions?.find(
            (action: HeroActionItem) => action.type === HeroAction.ExternalLink
          )?.config
        }
      />
    ),
  };

  return (
    <MultiButtonWrapper>
      {heroActions.map((action: HeroActionItem) => (
        <div key={action.type}>{actions[action.type]}</div>
      ))}
    </MultiButtonWrapper>
  );
};

Builder.registerComponent(HeroActions, { name: 'Hero Actions' });
Builder.registerComponent(ShopButton, {
  name: 'Shop Button',
  inputs: [
    {
      name: 'isOnImage',
      type: 'boolean',
    },
    {
      name: 'buttonVariant',
      type: 'string',
      enum: Object.values(ButtonVariant),
    },
    {
      name: 'searchParams',
      friendlyName: 'Link To Search Params',
      helperText:
        'Useful for linking to specific filters, e.g. mode=filter-change&pub_category=accessories',
      type: 'string',
    },
  ],
});

export default HeroActions;
