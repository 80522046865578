/* eslint-disable no-nested-ternary */
import { Box } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { InformationModule, InlineTextButton } from '../../../components';
import { ContentDirection } from '../../../components/InformationModule/InformationModule';
import { useShopConfig } from '../../../hooks/shopConfig';
import {
  getBuyerContentConfig,
  getSellerContentConfig,
  getTradeInContentConfig,
} from '../aboutPageUtils';
import { useEnabledCustomerExperiences } from '../../../hooks/useEnabledCustomerExperiences';
import { useIsMobile } from '../../../hooks/useIsMobile';
import css from '../AboutPage.module.css';
import TypographyWrapper, {
  TypographyFormat,
} from '../../../components/TypographyWrapper/TypographyWrapper';

enum Tabs {
  Buyers = 'BUYERS',
  Sellers = 'SELLERS',
  TradeIn = 'TRADE_IN',
}

const HowItWorksV2: FC = () => {
  const {
    buyerSellerInfoOverrides,
    cashPayoutPercentage,
    creditPayoutPercentage,
    shopName,
    images,
  } = useShopConfig();
  const customIcons = images?.howItWorksCustomImages;
  const { isBrandDirectOnly, allowBuy, allowSell, allowTradeIn, isTradeInOnly } =
    useEnabledCustomerExperiences();
  const isMobile = useIsMobile();

  const [tabSelected, setTabSelected] = useState(Tabs.Buyers);

  const buyerContent = getBuyerContentConfig(
    creditPayoutPercentage,
    cashPayoutPercentage,
    customIcons,
    buyerSellerInfoOverrides
  );
  const sellerContent = getSellerContentConfig(
    creditPayoutPercentage,
    cashPayoutPercentage,
    customIcons,
    buyerSellerInfoOverrides
  );
  const tradeInContent = getTradeInContentConfig(
    shopName,
    customIcons,
    buyerSellerInfoOverrides,
    !isTradeInOnly
  );

  const content =
    tabSelected === Tabs.TradeIn
      ? tradeInContent
      : tabSelected === Tabs.Buyers
      ? buyerContent
      : sellerContent;

  const tabs = (
    <Box display="flex" alignItems="center">
      {allowBuy && (
        <InlineTextButton onClick={() => setTabSelected(Tabs.Buyers)} className={css.howItWorksTab}>
          <Box mr={5}>
            <TypographyWrapper
              variant="h1"
              format={
                tabSelected === Tabs.Buyers
                  ? TypographyFormat.Underlined
                  : TypographyFormat.HoverUnderlined
              }
            >
              {isMobile ? 'Buy' : 'For Buyers'}
            </TypographyWrapper>
          </Box>
        </InlineTextButton>
      )}
      {allowSell && (
        <InlineTextButton
          onClick={() => setTabSelected(Tabs.Sellers)}
          className={css.howItWorksTab}
        >
          <TypographyWrapper
            variant="h1"
            format={
              tabSelected === Tabs.Sellers
                ? TypographyFormat.Underlined
                : TypographyFormat.HoverUnderlined
            }
          >
            {isMobile ? 'Sell' : 'For Sellers'}
          </TypographyWrapper>
        </InlineTextButton>
      )}
      {allowTradeIn && (
        <InlineTextButton
          onClick={() => setTabSelected(Tabs.TradeIn)}
          className={css.howItWorksTab}
        >
          <Box ml={5}>
            <TypographyWrapper
              variant="h1"
              format={
                tabSelected === Tabs.TradeIn
                  ? TypographyFormat.Underlined
                  : TypographyFormat.HoverUnderlined
              }
            >
              {isMobile ? 'Trade In' : 'For Trade-Ins'}
            </TypographyWrapper>
          </Box>
        </InlineTextButton>
      )}
    </Box>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      px={2}
      height={{ xs: '450px', md: '300px' }}
    >
      {!isBrandDirectOnly && <Box mb={5}>{tabs}</Box>}
      <InformationModule content={content} direction={ContentDirection.Horizontal} />
    </Box>
  );
};

export default HowItWorksV2;
