import { getConditionsFilterConfig } from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { BuilderSections } from '../../util/builder';

export const eksterShopConfig = {
  ...getDefaultShopConfig(),
  filters: [...custom.filters, getConditionsFilterConfig(), getBrandDirectFilterConfig()],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: '37f752a4feb643258fd8b639fe061d84',
    },
  },
};
