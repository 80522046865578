/* eslint-disable camelcase */
import axiosOriginal from 'axios';
import adapter from 'axios/lib/adapters/xhr';
import { Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { EmailSubscribeSource } from '../../types/apollo/generated/types.generated';
import { TreetShopConfigResponse } from '../../types/contentful/treetShop';
import { RequestStatus } from '../../types/requestStatus';
import { subscribeEmail, uploadToAirtable } from '../../util/api';
import { apiBaseUrl } from '../../util/envHelpers';
import { storableError } from '../../util/errors';
import { trackSubscribe } from '../../util/heap';
import { handle } from '../../util/helpers';
import * as log from '../../util/log';
import SENDGRID_CONTACT_LISTS from '../../util/sendgrid';
import { getUrlSearchParams } from '../../util/urlHelpers';

// Axios was having adapter not fonud issues during ssr call
// Reference: https://github.com/axios/axios/issues/2968
const axios = axiosOriginal.create({ adapter });

// ================ Action types ================ //

export const FETCH_TREET_SHOP_CONFIG_REQUEST =
  'app/TreetShopLandingPage/FETCH_TREET_SHOP_CONFIG_REQUEST';
export const FETCH_TREET_SHOP_CONFIG_SUCCESS =
  'app/TreetShopLandingPage/FETCH_TREET_SHOP_CONFIG_SUCCESS';
export const FETCH_TREET_SHOP_CONFIG_ERROR =
  'app/TreetShopLandingPage/FETCH_TREET_SHOP_CONFIG_ERROR';

export const SUBSCRIBE_TREET_SHOP_REQUEST = 'app/TreetShopLandingPage/SUBSCRIBE_TREET_SHOP_REQUEST';
export const SUBSCRIBE_TREET_SHOP_SUCCESS = 'app/TreetShopLandingPage/SUBSCRIBE_TREET_SHOP_SUCCESS';
export const SUBSCRIBE_TREET_SHOP_ERROR = 'app/TreetShopLandingPage/SUBSCRIBE_TREET_SHOP_ERROR';

export const REFER_BRAND_REQUEST = 'app/TreetShopLandingPage/REFER_BRAND_REQUEST';
export const REFER_BRAND_SUCCESS = 'app/TreetShopLandingPage/REFER_BRAND_SUCCESS';
export const REFER_BRAND_ERROR = 'app/TreetShopLandingPage/REFER_BRAND_ERROR';

interface FetchTreetShopConfigRequest {
  type: typeof FETCH_TREET_SHOP_CONFIG_REQUEST;
}

interface FetchTreetShopConfigSuccess {
  type: typeof FETCH_TREET_SHOP_CONFIG_SUCCESS;
  response: TreetShopConfigResponse;
}

interface FetchTreetShopConfigError {
  type: typeof FETCH_TREET_SHOP_CONFIG_ERROR;
  error: any;
}
interface SubscribeTreetShopRequest {
  type: typeof SUBSCRIBE_TREET_SHOP_REQUEST;
}

interface SubscribeTreetShopSuccess {
  type: typeof SUBSCRIBE_TREET_SHOP_SUCCESS;
  response: any;
}

interface SubscribeTreetShopError {
  type: typeof SUBSCRIBE_TREET_SHOP_ERROR;
  error: any;
}

interface ReferBrandRequest {
  type: typeof REFER_BRAND_REQUEST;
}

interface ReferBrandSuccess {
  type: typeof REFER_BRAND_SUCCESS;
}

interface ReferBrandError {
  type: typeof REFER_BRAND_ERROR;
  error: any;
}

type TreetShopLandingPageActionType =
  | FetchTreetShopConfigRequest
  | FetchTreetShopConfigSuccess
  | FetchTreetShopConfigError
  | SubscribeTreetShopRequest
  | SubscribeTreetShopSuccess
  | SubscribeTreetShopError
  | ReferBrandRequest
  | ReferBrandSuccess
  | ReferBrandError;

// ================ Reducer ================ //

export interface TreetShopLandingPageState {
  treetShopConfig: TreetShopConfigResponse | null;
  fetchTreetShopConfigStatus: RequestStatus;
  fetchTreetShopConfigError: any | null;
  subscribeTreetShopStatus: RequestStatus;
  subscribeTreetShopError: any | null;
  referBrandStatus: RequestStatus;
  referBrandError: any | null;
}

const initialState = {
  treetShopConfig: null,
  fetchTreetShopConfigStatus: RequestStatus.Ready,
  fetchTreetShopConfigError: null,
  subscribeTreetShopStatus: RequestStatus.Ready,
  subscribeTreetShopError: null,
  referBrandStatus: RequestStatus.Ready,
  referBrandError: null,
};

export default function LandingPageV2Reducer(
  state: TreetShopLandingPageState = initialState,
  action: TreetShopLandingPageActionType
): TreetShopLandingPageState {
  switch (action.type) {
    case FETCH_TREET_SHOP_CONFIG_REQUEST: {
      return {
        ...state,
        fetchTreetShopConfigStatus: RequestStatus.Pending,
        fetchTreetShopConfigError: null,
      };
    }
    case FETCH_TREET_SHOP_CONFIG_SUCCESS: {
      return {
        ...state,
        fetchTreetShopConfigStatus: RequestStatus.Success,
        treetShopConfig: action.response,
      };
    }
    case FETCH_TREET_SHOP_CONFIG_ERROR: {
      return {
        ...state,
        fetchTreetShopConfigStatus: RequestStatus.Error,
        fetchTreetShopConfigError: action.error,
      };
    }
    case SUBSCRIBE_TREET_SHOP_REQUEST: {
      return {
        ...state,
        subscribeTreetShopStatus: RequestStatus.Pending,
        subscribeTreetShopError: null,
      };
    }
    case SUBSCRIBE_TREET_SHOP_SUCCESS: {
      return {
        ...state,
        subscribeTreetShopStatus: RequestStatus.Success,
      };
    }
    case SUBSCRIBE_TREET_SHOP_ERROR: {
      return {
        ...state,
        subscribeTreetShopStatus: RequestStatus.Error,
        subscribeTreetShopError: action.error,
      };
    }
    case REFER_BRAND_REQUEST: {
      return {
        ...state,
        referBrandStatus: RequestStatus.Pending,
        referBrandError: null,
      };
    }
    case REFER_BRAND_SUCCESS: {
      return {
        ...state,
        referBrandStatus: RequestStatus.Success,
      };
    }
    case REFER_BRAND_ERROR: {
      return {
        ...state,
        referBrandStatus: RequestStatus.Error,
        referBrandError: action.error,
      };
    }
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const fetchTreetShopConfigRequest = () => ({
  type: FETCH_TREET_SHOP_CONFIG_REQUEST,
});

export const fetchTreetShopConfigSuccess = (response: TreetShopConfigResponse) => ({
  type: FETCH_TREET_SHOP_CONFIG_SUCCESS,
  response,
});

export const fetchTreetShopConfigError = (error: any) => ({
  type: FETCH_TREET_SHOP_CONFIG_ERROR,
  error,
});

export const subscribeTreetShopRequest = () => ({
  type: SUBSCRIBE_TREET_SHOP_REQUEST,
});

export const subscribeTreetShopSuccess = () => ({
  type: SUBSCRIBE_TREET_SHOP_SUCCESS,
});

export const subscribeTreetShopError = (error: any) => ({
  type: SUBSCRIBE_TREET_SHOP_ERROR,
  error,
});

export const referBrandRequest = () => ({
  type: REFER_BRAND_REQUEST,
});

export const referBrandSuccess = () => ({
  type: REFER_BRAND_SUCCESS,
});

export const referBrandError = (error: any) => ({
  type: REFER_BRAND_ERROR,
  error,
});

/* ================ Thunks ================ */

export const fetchTreetShopConfig =
  (shouldShowPreview?: boolean) => async (dispatch: Dispatch, getState: () => any) => {
    const { origin } = getState().initial;

    dispatch(fetchTreetShopConfigRequest());
    const urlSearchParams = getUrlSearchParams();
    const isPreview = shouldShowPreview || urlSearchParams.has('preview');
    const previewParam = isPreview ? '?preview=true' : '';

    const [response, error] = await handle(
      axios.get(`${apiBaseUrl(origin)}/api/treetShopConfig${previewParam}`)
    );

    if (error || !response?.data?.data?.treetShopConfig) {
      console.error(error);
      let e;
      if (error) {
        e = error?.error || storableError(error);
      } else {
        e = 'No TreetShopConfig response from server';
      }
      log.error(error, 'fetch-treet-shop-config-from-server-failed', e);
      dispatch(fetchTreetShopConfigError(e));
      throw error;
    }

    return dispatch(fetchTreetShopConfigSuccess(response.data.data.treetShopConfig));
  };

export const subscribeEmailToTreetShop =
  (email: string, subscribeSource: EmailSubscribeSource) => async (dispatch: Dispatch) => {
    dispatch(subscribeTreetShopRequest());

    const contact = { email };

    const [response, error] = await handle(
      subscribeEmail({
        contact,
        listId: SENDGRID_CONTACT_LISTS.treetShopSubscribeList.listId,
      })
    );

    if (error) {
      console.error(error);
      log.error(error, 'subscribe-treet-shop-email-failed', { contact });
      dispatch(subscribeTreetShopError(error));
      return null;
    }

    trackSubscribe(
      'Treet Shop',
      email,
      SENDGRID_CONTACT_LISTS.treetShopSubscribeList.listName,
      subscribeSource
    );
    dispatch(subscribeTreetShopSuccess());
    return response;
  };

export const referBrand =
  (params: { brand: string; notes?: string }) => async (dispatch: Dispatch) => {
    const { brand, notes } = params;
    dispatch(referBrandRequest());
    const fields = { brand, notes };

    const [response, error] = await handle(
      uploadToAirtable({
        baseName: 'Brand Recommendations',
        fields,
      })
    );

    if (error) {
      console.error(error);
      log.error(error, 'refer-brand-failed', { fields, error });
      dispatch(referBrandError(error));
      return null;
    }

    dispatch(referBrandSuccess());
    return response;
  };

export const loadData = () => async (dispatch: ThunkDispatch<any, any, any>) =>
  dispatch(fetchTreetShopConfig());
