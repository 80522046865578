import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import { useLocation } from 'react-router-dom';
import { GemPing, NamedLink } from '..';
import TypographyWrapper, {
  TypographyFormat,
  TypographyWeight,
} from '../TypographyWrapper/TypographyWrapper';
import ISOHeader from './ISOHeader';
import { useActiveModal } from '../../hooks/useActiveModal';
import { ModalType } from '../../ducks/modal.duck';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { Feature } from '../../util/featureFlags';
import css from './ISOModal.module.css';

const ISOSuccessPane: FC = () => {
  const { values } = useFormState();
  const { closeModal: closeISOModal } = useActiveModal(ModalType.ISO);
  const isEmailManagementEnabled = useFeatureFlags(Feature.EmailManagement);
  const location = useLocation();

  const title = values?.shopifyProduct?.title;
  const imgSrc = values?.shopifyProduct?.featuredImage?.originalSrc;

  return (
    <Box height="100%">
      <ISOHeader headerCopy="We’re On It!" />
      <Box
        py={{ xs: 10, md: 5 }}
        px={2}
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems="center"
        justifyContent="center"
        flexGrow={1}
      >
        <Box position="relative">
          {/* eslint-disable jsx-a11y/img-redundant-alt, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
          <img className={css.productImage} src={imgSrc} alt="product photo" />
          <Box width="100px" height="100px" position="absolute" top={0} right={0}>
            <GemPing />
          </Box>
        </Box>
        <Box px={{ xs: 5, md: 0 }} textAlign={{ xs: 'center', md: 'left' }}>
          <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
            We’ll email you when
          </TypographyWrapper>
          <TypographyWrapper
            variant="body1"
            weight={TypographyWeight.Bold}
            typographyOverrides={{ display: 'inline' }}
          >
            &nbsp;{title}&nbsp;
          </TypographyWrapper>
          <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
            lists!
          </TypographyWrapper>
          {isEmailManagementEnabled && location.pathname !== '/notification-settings' && (
            <Box pt={1}>
              <NamedLink
                name="NotificationSettingsPage"
                to={{ hash: 'item-requests' }}
                onClick={closeISOModal}
              >
                <TypographyWrapper variant="body1" format={TypographyFormat.Underlined}>
                  Manage your item requests
                </TypographyWrapper>
              </NamedLink>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ISOSuccessPane;
