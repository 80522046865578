/* eslint-disable import/prefer-default-export */
import { getDefaultShopConfig, getDefaultTradeInShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { BuilderSections } from '../../util/builder';
import { getGenderFilterConfig } from '../filters/gender';

const kurufootwearSizes = [
  '5',
  '5.5',
  '6',
  '6.5',
  '7',
  '7.5',
  '8',
  '8.5',
  '9',
  '9.5',
  '10',
  '10.5',
  '11',
  '11.5',
  '12',
  '12.5',
  '13',
  '13.5',
  '14',
];

const kurufootwearConditions = [
  {
    key: Conditions.NewWithTags,
    label: 'Brand New',
    shortLabel: 'New',
    description:
      'Your shoes have never been worn (other than perhaps trying it on). There are no quirks or issues whatsoever.',
  },
  {
    key: Conditions.Good,
    label: 'Preloved',
    shortLabel: 'Good',
    description:
      'Your shoes have only been used and have been well maintained. There may be some small signs of wear and quirks like small stain or a scuff mark, but they’re overall in good shape.',
  },
];

export const kurufootwearShopConfig = {
  ...getDefaultShopConfig(),
  ...getDefaultTradeInShopConfig(),
  filters: [
    ...custom.filters,
    getGenderFilterConfig(),
    getConditionsFilterConfig({ options: kurufootwearConditions }),
    getSizeFilterConfig({ sizes: kurufootwearSizes }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: 'c07a4746372f47a4a916dc07c6d923f6',
    },
  },
};
