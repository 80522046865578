import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getDefaultShopConfig, getDefaultTradeInShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';

const livieandlucaSizes = [
  '1Y',
  '2Y',
  '3Y',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
];

const livieandlucaConditions = [
  {
    key: Conditions.NewWithTags,
    label: 'New In Box',
    shortLabel: 'New in Box',
    description: 'Your shoes have never been worn and they’re still in the original box.',
  },
  {
    key: Conditions.NewWithoutTags,
    label: 'New Without Box',
    shortLabel: 'New w/o Box',
    description:
      'Your shoes have never been worn (other than perhaps trying it on), but you no longer have the box. There are no quirks or issues whatsoever.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent Condition',
    shortLabel: 'Excellent',
    description:
      'Your shoes have only been lightly used and have been extremely well maintained. There may be some small signs of wear, but nothing major.',
  },
  {
    key: Conditions.Good,
    label: 'Good Condition',
    shortLabel: 'Good',
    description:
      'You’ve worn the shoes and they’re well maintained. There may be some quirks like a small stain or a scuff mark, but they’re overall in good shape.',
  },
];

export const livieandlucaShopConfig = {
  ...getDefaultShopConfig(),
  ...getDefaultTradeInShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: livieandlucaConditions }),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: livieandlucaSizes }),
  ],
};
