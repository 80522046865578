import { Box, Grid } from '@material-ui/core';
import moment from 'moment';
import React, { FC, useContext, useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { canProductBeShown, shouldFillVariantOption } from './Shopify.utils';
import { useShopConfig } from '../../hooks/shopConfig';
import { Product as ShopifyProduct } from '../../types/shopify/product';
import { FindItemMethod } from '../../util/listings/listing';
import Product from './Product';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import ItemNameForm from '../../containers/EditListingPage/ItemNameForm';
import { fetchProductById } from '../../util/api';
import { useLazyApi } from '../../hooks/useLazyApi';
import AppContext from '../../context/AppContext';
import { useBrandCountryConfig } from '../../hooks/useCountryConfig';
import TypographyWrapper, { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';

interface ShopifyOrderProps {
  findItemMethod: FindItemMethod;
  order: any;
  emailOrPhoneForOrder?: string;
  orderConfirmationNumber?: string;
}

const ShopifyOrder: FC<ShopifyOrderProps> = (props) => {
  const { findItemMethod, order, emailOrPhoneForOrder, orderConfirmationNumber } = props;

  const form = useForm();
  const { values } = useFormState();
  const { lineItemId, shopifyProduct } = values;

  const { treetId } = useContext(AppContext);
  const { countryCode } = useBrandCountryConfig();
  const { allowTradeIn } = useEnabledCustomerExperiences();
  const shopConfig = useShopConfig();
  const { allowUserGeneratedTitle } = shopConfig;

  const { lazyQuery: lazyFetchProductById, data: productById } = useLazyApi(fetchProductById);

  useEffect(() => {
    if (!productById) return;
    const { product } = productById;
    form.change('shopifyProduct', product);
  }, [productById]);

  const handleChange = async (
    product?: ShopifyProduct,
    variant?: any,
    productLineItemId?: string
  ) => {
    // Fetch the product again so that we can get the full product, including its variant options
    lazyFetchProductById({
      treetId,
      countryCode,
      productId: product?.id,
      shouldIncludeTradeInPriceMetafield: allowTradeIn,
    });

    const itemVariantOptions = variant?.selectedOptions.reduce(
      (acc: { [option: string]: string }, option: any) => {
        if (shouldFillVariantOption(option, shopConfig)) {
          return { ...acc, [option.name.toLowerCase()]: option.value };
        }
        return acc;
      },
      {}
    );

    form.change('category', undefined);
    form.change('categories', undefined);
    form.change('condition', undefined);
    form.change('tags', undefined);
    form.change('userGeneratedTitle', undefined);
    form.change('findItemMethod', findItemMethod);
    form.change('lineItemId', productLineItemId);
    form.change('shopifyProduct', undefined);
    form.change('shopifyProductVariant', variant);
    form.change('emailOrPhoneForOrder', emailOrPhoneForOrder);
    form.change('orderConfirmationNumber', orderConfirmationNumber);
    form.change('selectedVariantOptions', itemVariantOptions);
  };

  const lineItems = order.node?.lineItems?.edges || [];
  const products = lineItems.reduce((acc: any[], lineItem: any) => {
    // If a variant or a product was deleted, then variant/product may be missing
    const product = lineItem.node?.product;
    const shouldIncludeProduct = product && canProductBeShown(product, shopConfig);
    if (!shouldIncludeProduct) return acc;
    return [
      ...acc,
      <Product
        product={product}
        selectedProductId={shopifyProduct?.id}
        lineItemId={lineItem.node?.id}
        selectedLineItemId={lineItemId}
        variant={lineItem.node?.variant}
        onChange={handleChange}
      />,
    ];
  }, []);
  const orderDate = order.node?.createdAt
    ? moment(order.node?.createdAt).format('MMM DD, YYYY')
    : null;

  const shopifyProductIds = lineItems.map((lineItem: any) => lineItem.node?.product?.id);
  const isItemInOrderSelected = !!shopifyProductIds.find((id: string) => id === shopifyProduct?.id);

  return (
    <Box display="flex" flexDirection="column" pb={1}>
      <TypographyWrapper variant="body1" weight={TypographyWeight.Bold}>
        {`Order ${order.node?.name} (${orderDate})`}
      </TypographyWrapper>
      {products.length > 0 && (
        <Grid container spacing={3} style={{ paddingTop: '8px' }}>
          {products}
        </Grid>
      )}
      {allowUserGeneratedTitle && isItemInOrderSelected && (
        <Box py={1}>
          <ItemNameForm />
        </Box>
      )}
    </Box>
  );
};

export default ShopifyOrder;
