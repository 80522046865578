import { Box, Divider, Typography } from '@material-ui/core';
import classNames from 'classnames';
import React, { FC, RefObject, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isEmpty, isEqual } from 'lodash';
import Button from '../../components/Button/Button';
import { FormattedMessage, IconGemFilled, NamedLink, SearchResultsPanel } from '../../components';
import Banner from '../../components/Banner/Banner';
import IconSearchSubscribe from '../../components/Icons/IconSearchSubscribe/IconSearchSubscribe';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { setSavedSearchSource } from '../../ducks/user.duck';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { useIsMobile } from '../../hooks/useIsMobile';
import { EmailSubscribeSource } from '../../types/apollo/generated/types.generated';
import { getLocalStorage, setLocalStorage } from '../../util/localStorageHelpers';
import { parse } from '../../util/urlHelpers';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { Feature } from '../../util/featureFlags';
import AppContext from '../../context/AppContext';
import { useAllowedFindItemMethods } from '../../hooks/useAllowedFindItemMethods';
import { ModalType, setActiveModal } from '../../ducks/modal.duck';
import { LandingPageV2State } from './LandingPageV2.duck';
import { trackClickISOBanner, trackViewSearchResults } from '../../util/heap';
import TypographyWrapper, {
  TypographyFormat,
  TypographyWeight,
} from '../../components/TypographyWrapper/TypographyWrapper';
import { FrenzyApiModes } from '../../types/frenzy/query';
import { useShopConfig, useShopConfigV2 } from '../../hooks/shopConfig';
import RecentArrivalsCarousel from '../../components/RecentArrivalsCarousel/RecentArrivalsCarousel';
import { RecentArrivalsCarouselState } from '../../ducks/recentArrivalsCarousel.duck';
import { useIsShopSideLaunched } from '../../hooks/useIsShopSideLaunched';
import { MIN_CAROUSEL_LISTINGS } from '../../components/ListingItemsCarousel/ListingItemsCarousel';
import css from './LandingPageV2.module.css';

interface SearchPanelProps {
  listingsAreLoaded: boolean;
  totalItems: number;
  areFiltersApplied: boolean;
  subscribeBannerActionScrollToRef: RefObject<HTMLHeadingElement>;
}

const RecentlyListedListingsSearchResults = () => {
  const { recentListings } = useSelector<any>(
    (state) => state.recentArrivalsCarousel
  ) as RecentArrivalsCarouselState;

  const isShopSideLaunched = useIsShopSideLaunched();

  // Render the divider only if the Recent Arrivals Carousel is displayed
  const displayDivider = isShopSideLaunched && recentListings.length >= MIN_CAROUSEL_LISTINGS;

  return (
    <Box>
      {displayDivider && (
        <Box my={3}>
          <Divider />
        </Box>
      )}
      <RecentArrivalsCarousel />
    </Box>
  );
};

interface SearchResultsBannerProps {
  areListingsLoaded: boolean;
  areFiltersApplied: boolean;
  totalItems: number;
  subscribeBannerActionScrollToRef: RefObject<HTMLHeadingElement>;
}

const SearchResultsBanner: FC<SearchResultsBannerProps> = (props) => {
  const { areListingsLoaded, areFiltersApplied, totalItems, subscribeBannerActionScrollToRef } =
    props;

  const { treetId } = useContext(AppContext);
  const { shopName } = useShopConfig();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const { shouldAllowSearch: hasProductCatalog } = useAllowedFindItemMethods();
  const isISOEnabled = useFeatureFlags(Feature.InSearchOf);

  const { pagination, searchParams } = useSelector<any>(
    (state) => state.LandingPageV2
  ) as LandingPageV2State;

  const subscribeBannerLocalStorageId = `seenSizeSubscribeBanner-${treetId}`;

  const handleOnSubscribeBannerClose = () => setLocalStorage(subscribeBannerLocalStorageId, 'true');

  const handleSubscribeBannerActionClick = () => {
    setLocalStorage(subscribeBannerLocalStorageId, 'true');
    dispatch(setSavedSearchSource({ source: EmailSubscribeSource.SearchResultsBanner }));
    subscribeBannerActionScrollToRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const handleISOBannerClick = () => {
    trackClickISOBanner(treetId, shopName, pagination?.page || 1);
    dispatch(setActiveModal(ModalType.ISO));
    dispatch(setSavedSearchSource({ source: EmailSubscribeSource.SearchResultsBannerIso }));
  };

  const hasResults = areListingsLoaded && totalItems > 0;
  const hasNoResults = areListingsLoaded && totalItems === 0;

  const hasInteractedWithShop =
    (pagination && pagination!.page > 1) || areFiltersApplied || searchParams?.mode;
  const hasUserSeenSizeSubscribeBanner = getLocalStorage(subscribeBannerLocalStorageId) === 'true';
  const showSizeSubscribeBanner =
    !isISOEnabled &&
    ((hasNoResults && areListingsLoaded) ||
      (hasInteractedWithShop && !hasUserSeenSizeSubscribeBanner));

  if (
    isISOEnabled &&
    areListingsLoaded &&
    hasProductCatalog &&
    (hasResults || (hasNoResults && isEmpty(searchParams?.keywords)))
  ) {
    return (
      <Banner
        bodyText="Looking for a specific item?"
        actionText="Submit an item request."
        icon={<IconGemFilled />}
        onActionClick={handleISOBannerClick}
        mb={isMobile ? 1 : 2}
      />
    );
  }

  if (showSizeSubscribeBanner) {
    return (
      <Banner
        bodyText="Not seeing what you’re looking for?"
        actionText="Get notified when items are listed."
        icon={<IconSearchSubscribe />}
        onClose={handleOnSubscribeBannerClose}
        onActionClick={handleSubscribeBannerActionClick}
        mb={isMobile ? 1 : 2}
      />
    );
  }

  return null;
};

const SearchPanel: FC<SearchPanelProps> = (props) => {
  const { listingsAreLoaded, totalItems, areFiltersApplied, subscribeBannerActionScrollToRef } =
    props;

  const rootState = useSelector<any>((state) => state) as any;
  const { currentPageResultIds, pagination, searchListingsError } = useSelector<any>(
    (state) => state.LandingPageV2
  ) as LandingPageV2State;
  const listings = getListingsById(rootState, currentPageResultIds);

  const { treetId } = useContext(AppContext);
  const { shopName } = useShopConfigV2();
  const { allowSell, isListTradeInOnly } = useEnabledCustomerExperiences();
  const isMobile = useIsMobile();
  const location = useLocation();
  const dispatch = useDispatch();

  const searchParams = parse(location.search);
  if (searchParams?.keywords && listingsAreLoaded) {
    trackViewSearchResults(totalItems, searchParams.keywords, treetId);
  }

  const handleISOBannerClick = () => {
    trackClickISOBanner(treetId, shopName, pagination?.page || 1);
    dispatch(setActiveModal(ModalType.ISO));
    dispatch(setSavedSearchSource({ source: EmailSubscribeSource.SearchResultsBannerIso }));
  };

  const clearFiltersOrSearchLink = (
    <NamedLink
      name="LandingPage"
      to={{
        state: { scrollOnRender: true },
        search: 'mode=raw-query',
      }}
    >
      <TypographyWrapper
        variant="body1"
        format={TypographyFormat.Underlined}
        weight={TypographyWeight.Bold}
      >
        {searchParams.keywords ? 'Clear search' : 'Clear filters'}
      </TypographyWrapper>
    </NamedLink>
  );

  const listItemLink = (
    <NamedLink name="NewListingPage">
      <TypographyWrapper
        variant="body2"
        format={TypographyFormat.Underlined}
        typographyOverrides={{ display: 'inline' }}
      >
        {isListTradeInOnly ? 'trade in your item' : 'list your own item'}
      </TypographyWrapper>
    </NamedLink>
  );

  const hasResults = listingsAreLoaded && totalItems > 0;
  const hasNoResults = listingsAreLoaded && totalItems === 0;
  const shouldShowRecentArrivals = hasNoResults && !isEmpty(searchParams?.keywords);

  return (
    <>
      <Box px={isMobile ? 2 : 8} py={isMobile ? 1 : 2}>
        <div className={css.searchResultsWrapper}>
          {!!searchListingsError && (
            <h2 className={css.error}>
              <FormattedMessage id="SearchPage.searchError" />
            </h2>
          )}
          <SearchResultsBanner
            areListingsLoaded={listingsAreLoaded}
            areFiltersApplied={areFiltersApplied}
            totalItems={totalItems}
            subscribeBannerActionScrollToRef={subscribeBannerActionScrollToRef}
          />
          {hasNoResults && !areFiltersApplied && (
            <Box py={1}>
              <Typography variant="body2">
                {!allowSell ? (
                  'All items have recently sold! Check back soon.'
                ) : (
                  <FormattedMessage id="SearchFiltersPrimary.noResults" values={{ listItemLink }} />
                )}
              </Typography>
            </Box>
          )}
          {hasNoResults && areFiltersApplied && (
            <Box
              my={3}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
            >
              <Box my={1}>
                <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
                  No results for{' '}
                </TypographyWrapper>
                <TypographyWrapper
                  variant="body1"
                  weight={TypographyWeight.Bold}
                  typographyOverrides={{ display: 'inline' }}
                >
                  {`“${searchParams.keywords}”`}
                </TypographyWrapper>
              </Box>
              <Box my={1}>
                <TypographyWrapper variant="body1">
                  But you can still submit an item request! We’ll find the exact item you have in
                  mind and let you know when it lists!
                </TypographyWrapper>
              </Box>
              <Box my={1}>
                <Button onClick={handleISOBannerClick}>Submit An Item Request</Button>
              </Box>
              {clearFiltersOrSearchLink}
            </Box>
          )}
          {hasResults && (
            <Box mb={2} display="flex" flexDirection="column" alignItems="start">
              {searchParams.keywords ? (
                <>
                  <Box>
                    <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
                      {`${totalItems} results for `}
                    </TypographyWrapper>
                    <TypographyWrapper
                      variant="body1"
                      typographyOverrides={{ display: 'inline' }}
                      weight={TypographyWeight.Bold}
                    >
                      {`“${searchParams.keywords}”`}
                    </TypographyWrapper>
                  </Box>
                  {clearFiltersOrSearchLink}
                </>
              ) : (
                searchParams.mode === FrenzyApiModes.FilterChange && (
                  <>
                    <Box>
                      <TypographyWrapper
                        variant="body1"
                        typographyOverrides={{ display: 'inline' }}
                      >
                        {`${totalItems} results`}
                      </TypographyWrapper>
                    </Box>
                    {clearFiltersOrSearchLink}
                  </>
                )
              )}
            </Box>
          )}
          <div
            className={classNames({
              [css.newSearchInProgress]: !listingsAreLoaded,
            })}
          >
            {shouldShowRecentArrivals && <RecentlyListedListingsSearchResults />}
            <SearchResultsPanel
              listings={listings}
              pagination={pagination}
              search={searchParams}
              areListingsLoaded={listingsAreLoaded}
            />
          </div>
        </div>
      </Box>
    </>
  );
};

const areEqual = (prevProps: SearchPanelProps, nextProps: SearchPanelProps) =>
  isEqual(prevProps, nextProps);

export default React.memo(SearchPanel, areEqual);
