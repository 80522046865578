import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import TypographyWrapper, { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import ListingFeedbackHeader from './ListingFeedbackHeader';
import { ListingWithAuthor } from '../../types/sharetribe/listing';
import { useShopConfig } from '../../hooks/shopConfig';
import {
  getDarkerBrandCssBackgroundColor,
  getPercentAlteredHexColor,
} from '../../util/colors/colors';
import { defaultTreetStyles } from '../../shopConfig/config';
import { useIsMobile } from '../../hooks/useIsMobile';

interface ListingFeedbackEducationPaneProps {
  listing: ListingWithAuthor;
}

const ListingFeedbackEducationPane: FC<ListingFeedbackEducationPaneProps> = (props) => {
  const { listing } = props;

  const isMobile = useIsMobile();
  const { css: brandCss } = useShopConfig();
  const darkerBackgroundColor = getDarkerBrandCssBackgroundColor(brandCss);
  const borderCss = `1px solid ${
    getPercentAlteredHexColor(darkerBackgroundColor, -8) || defaultTreetStyles.gray20
  }`;

  const sellerName = listing.author?.attributes?.profile?.displayName;
  return (
    <>
      <ListingFeedbackHeader />
      <Box
        pt={{ xs: 10, md: 5 }}
        pb={{ xs: 24, md: 5 }}
        px={isMobile ? 4 : 6}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box pb={4}>
          <TypographyWrapper
            variant="h2"
            weight={TypographyWeight.Bold}
            typographyOverrides={{
              style: { textAlign: 'center' },
            }}
          >
            {`Ask ${sellerName} to update this listing`}
          </TypographyWrapper>
        </Box>
        <Box pb={4}>
          <TypographyWrapper
            variant="body1"
            typographyOverrides={{
              style: { textAlign: 'left' },
            }}
          >
            <TypographyWrapper
              variant="body1"
              weight={TypographyWeight.Bold}
              typographyOverrides={{ display: 'inline' }}
            >
              1. Send Feedback
            </TypographyWrapper>
            : Your feedback will be sent to the seller.
            <br />
            <br />
            <TypographyWrapper
              variant="body1"
              weight={TypographyWeight.Bold}
              typographyOverrides={{ display: 'inline' }}
            >
              2. Listing Is Updated
            </TypographyWrapper>
            : Seller may update the listing.
            <br />
            <br />
            <TypographyWrapper
              variant="body1"
              weight={TypographyWeight.Bold}
              typographyOverrides={{ display: 'inline' }}
            >
              3. Get Notified
            </TypographyWrapper>
            : You’ll receive an email when the listing is updated.
          </TypographyWrapper>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          border={borderCss}
          bgcolor={darkerBackgroundColor || defaultTreetStyles.gray10}
          p={3}
        >
          <TypographyWrapper
            variant="body2"
            typographyOverrides={{
              style: { textAlign: 'center' },
            }}
          >
            This is not a chat platform. You will not get a direct response from the seller.
          </TypographyWrapper>
        </Box>
      </Box>
    </>
  );
};

export default ListingFeedbackEducationPane;
