import Box from '@material-ui/core/Box';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetActiveModal } from '../../ducks/modal.duck';
import { fetchBundleAuthorListings } from '../../ducks/shoppingBag.duck';
import {
  Bundle,
  Button,
  DialogWithCloseButton,
  Divider,
  DynamicValueWrapper,
  IconButton,
  IconListFilled,
  IconTrash,
  IconTrashWithBorder,
  NamedLink,
  TypographyWrapper,
} from '..';
import { initializeCardPaymentData } from '../../ducks/stripe.duck';
import { removeListingsFromShoppingBag } from '../../ducks/user.duck';
import { useRouteConfiguration } from '../../hooks/useRouteConfiguration';
import { ListingWithAuthorAndImages } from '../../types/sharetribe/listing';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import { pluralize } from '../../util/strings';
import { ButtonProps, ButtonVariant } from '../Button/Button';
import { TypographyFormat, TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import { defaultTreetStyles } from '../../shopConfig/config';
import css from './ShoppingBagBundles.module.css';

const ActionButton: FC<ButtonProps> = (props: ButtonProps) => {
  const { children, ...rest } = props;

  return (
    <Button className={css.actionButton} {...rest}>
      {children}
    </Button>
  );
};

interface DeleteBundleModalProps {
  title: string;
  children: ReactNode;
}

export const DeleteBundleModal: FC<DeleteBundleModalProps> = (props: DeleteBundleModalProps) => {
  const { title, children } = props;

  return (
    <>
      <div className={css.header}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          px={3}
          className={css.headerBar}
        >
          <TypographyWrapper variant="h2" weight={TypographyWeight.Bold}>
            {title}
          </TypographyWrapper>
        </Box>
        <Divider className={css.divider} />
      </div>
      <div className={css.content}>{children}</div>
    </>
  );
};

interface DeleteBundleButtonProps {
  numItems: number;
  displayName: string;
}

const DeleteBundleButton: FC<DeleteBundleButtonProps & ButtonProps> = (
  props: DeleteBundleButtonProps & ButtonProps
) => {
  const { onClick, numItems, displayName } = props;

  const [isDeleteBundleModalOpen, setIsDeleteBundleModalOpen] = useState(false);

  const title = `Remove ${pluralize('Item', numItems, true)}`;

  return (
    <>
      <IconButton
        onClick={() => setIsDeleteBundleModalOpen(true)}
        icon={<IconTrash className={css.trashIcon} />}
      />
      <DialogWithCloseButton
        open={isDeleteBundleModalOpen}
        headerClassName={css.dialogHeader}
        fullWidth
        maxWidth="sm"
        onClose={() => {
          setIsDeleteBundleModalOpen(false);
        }}
      >
        <DeleteBundleModal title={title}>
          <>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              px={4}
              pt={{ xs: 20, md: 15 }}
              pb={20}
            >
              <IconTrashWithBorder className={css.trashIconRemoveItems} />
              <TypographyWrapper variant="body1">
                All items from{' '}
                <DynamicValueWrapper>
                  <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
                    {displayName}
                  </TypographyWrapper>
                </DynamicValueWrapper>{' '}
                will be removed from your shopping bag.
              </TypographyWrapper>
            </Box>
            <Box className={css.deleteBundleFooter}>
              <ActionButton onClick={onClick} variant={ButtonVariant.Danger}>
                {title}
              </ActionButton>
            </Box>
          </>
        </DeleteBundleModal>
      </DialogWithCloseButton>
    </>
  );
};

const CheckoutButton: FC<{ numItems: number } & ButtonProps> = (
  props: { numItems: number } & ButtonProps
) => {
  const { onClick, numItems } = props;
  return (
    <ActionButton
      onClick={onClick}
      enforcePagePreloadFor="CheckoutPage"
      style={{
        width: '120px',
        minHeight: '36px',
        padding: '0 8px',
        fontSize: '10px',
        backgroundColor: defaultTreetStyles.gray10,
        color: defaultTreetStyles.gray80,
      }}
    >
      Checkout ({numItems})
    </ActionButton>
  );
};

interface ShoppingBagBundleProps {
  isEditing: boolean;
  listings: ListingWithAuthorAndImages[];
}

const ShoppingBagBundle: FC<ShoppingBagBundleProps> = (props: ShoppingBagBundleProps) => {
  const { isEditing, listings } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const routes = useRouteConfiguration();

  const authorId = listings[0].author.id.uuid;
  const authorListingsCount = useSelector<any>(
    (state) => state.shoppingBag.bundleAuthorToListingIds[authorId]?.length || 0
  ) as number;

  const [isDeletingBundle, setIsDeletingBundle] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchBundleAuthorListings(authorId));
  }, []);

  const handleRemoveListings = (listingIds: string[]) => {
    dispatch(removeListingsFromShoppingBag({ listingIds, shouldQueueAbandonedBagEmail: true }));
  };

  const handleDeleteBundle = async () => {
    setIsDeletingBundle(true);
    await new Promise(() => handleRemoveListings(listings.map((l) => l.id.uuid)));
    setIsDeletingBundle(false);
  };

  const handleCloseShoppingBagModal = () => dispatch(resetActiveModal());

  const handleCheckout = () => {
    // Customize checkout page state with shopping bag listings
    const { setInitialValues: checkoutPageSetInitialValues } = findRouteByRouteName(
      'CheckoutPage',
      routes
    );

    dispatch(checkoutPageSetInitialValues(listings));
    handleCloseShoppingBagModal();

    // Clear previous Stripe errors from store if there is any
    dispatch(initializeCardPaymentData());

    // Redirect to CheckoutPage
    history.push(createResourceLocatorString('CheckoutPage', routes));
  };

  const { displayName } = listings[0].author.attributes.profile;

  const actionButton = isEditing ? (
    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
      <DeleteBundleButton
        onClick={handleDeleteBundle}
        numItems={listings.length}
        displayName={displayName}
      />
      <CheckoutButton onClick={handleCheckout} numItems={listings.length} />
    </Box>
  ) : (
    <CheckoutButton onClick={handleCheckout} numItems={listings.length} />
  );

  const heading = (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
        <>
          <TypographyWrapper
            variant="body1"
            weight={TypographyWeight.Bold}
            typographyOverrides={{ display: 'inline' }}
          >
            {pluralize('Item', listings.length, true)}
          </TypographyWrapper>{' '}
          from{' '}
          <NamedLink
            name="ProfilePage"
            params={{ id: authorId }}
            onClick={handleCloseShoppingBagModal}
          >
            <DynamicValueWrapper>
              <TypographyWrapper
                variant="body1"
                format={TypographyFormat.Underlined}
                typographyOverrides={{ display: 'inline' }}
              >
                {displayName}
              </TypographyWrapper>
            </DynamicValueWrapper>
          </NamedLink>
        </>
      </TypographyWrapper>
      {actionButton}
    </Box>
  );

  const addMoreButton = (
    <NamedLink
      name="ProfilePage"
      params={{ id: authorId }}
      className={css.addMoreButton}
      onClick={handleCloseShoppingBagModal}
    >
      <IconListFilled />
      <div className={css.addMoreText}>
        <TypographyWrapper
          variant="body2"
          typographyOverrides={{ style: { fontSize: '13px', lineHeight: '13px' } }}
        >
          Add items <br /> and save <br /> on shipping
        </TypographyWrapper>
      </div>
    </NamedLink>
  );

  return (
    <Bundle
      isEditing={isEditing}
      isLoading={isDeletingBundle}
      listings={listings}
      heading={heading}
      listingsInlineButton={
        authorListingsCount > listings.length && !isEditing ? addMoreButton : undefined
      }
      allowListingRedirect={!isEditing}
      handleRemoveListings={handleRemoveListings}
      referrerForLogging="Shopping Bag"
    />
  );
};

export default ShoppingBagBundle;
