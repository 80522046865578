/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTheme } from '@material-ui/core';
import { FormattedMessage, TypographyWrapper } from '..';
import { defaultTreetStyles } from '../../shopConfig/config';
import { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import css from './StripeBankAccountTokenInputField.module.css';

const StripeBankAccountRequiredInput = (props) => {
  const {
    className,
    rootClassName,
    inputType,
    formName,
    value,
    placeholder,
    onChange,
    onFocus,
    onBlur,
    isTouched,
    showStripeError,
    inputError,
    disabled,
    showInColumns,
  } = props;

  const theme = useTheme();
  const showInputError = isTouched && !!inputError;

  const classes = classNames(rootClassName || css.input, className, {
    [css.inputSuccess]: !!value,
    [css.inputError]: showInputError || showStripeError,
  });

  const columnsClass = showInColumns ? css.longForm : null;

  const inputProps = {
    className: classes,
    id: `${formName}.bankAccountToken.${inputType}`,
    value,
    placeholder,
    onChange,
    onFocus,
    onBlur,
    disabled,
  };

  const errorMessage = (
    <p className={css.error}>
      <TypographyWrapper
        variant="body1"
        typographyOverrides={{ style: { color: defaultTreetStyles.red80 } }}
      >
        {inputError}
      </TypographyWrapper>
    </p>
  );

  // Needed because we can't apply !important in inline styles
  let overrideRef;

  const body1Font = theme?.typography?.body1;

  // This is the only way to set the !important flag in React
  // TODO (sonia-y | TREET-1281): Remove this piece of code once we no longer use the fonts configured from config.js
  if (body1Font?.fontFamily) {
    overrideRef = (element) => {
      const sanitizedFontFamily = body1Font.fontFamily.replace('!important', '');
      if (element?.style)
        element.style.setProperty('font-family', sanitizedFontFamily, 'important');
    };
  }

  return (
    <div className={classNames(classes, columnsClass)}>
      <label htmlFor={inputProps.id}>
        <TypographyWrapper variant="body1" weight={TypographyWeight.Bold}>
          <FormattedMessage id={`StripeBankAccountTokenInputField.${inputType}.label`} />
        </TypographyWrapper>
      </label>
      <input {...inputProps} data-private="redact" style={body1Font} ref={overrideRef} />
      {showInputError ? errorMessage : null}
    </div>
  );
};

const { bool, func, string } = PropTypes;

StripeBankAccountRequiredInput.defaultProps = {
  rootClassName: null,
  className: null,
  inputError: null,
  disabled: false,
};

StripeBankAccountRequiredInput.propTypes = {
  rootClassName: string,
  className: string,
  inputType: string.isRequired,
  formName: string.isRequired,
  value: string.isRequired,
  placeholder: string.isRequired,
  onChange: func.isRequired,
  onFocus: func.isRequired,
  onBlur: func.isRequired,
  isTouched: bool.isRequired,
  showStripeError: bool.isRequired,
  inputError: string,
  disabled: bool,
};

export default StripeBankAccountRequiredInput;
