import Box from '@material-ui/core/Box';
import classNames from 'classnames';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import startCase from 'lodash/startCase';
import {
  Button,
  Divider,
  Empty,
  IconArrowRight,
  InlineTextButton,
  NamedLink,
  ShoppingBagBundles,
  TypographyWrapper,
} from '..';
import { getListingsByUuid } from '../../ducks/marketplaceData.duck';
import { ShoppingBagState } from '../../ducks/shoppingBag.duck';
import { useShopConfig } from '../../hooks/shopConfig';
import { RequestStatus } from '../../types/requestStatus';
import { ListingWithAuthorAndImages } from '../../types/sharetribe/listing';
import { pluralize } from '../../util/strings';
import { getShopsCanonicalRootUrl } from '../../shopConfig/configHelper';
import { Referrer } from '../../util/sessionHelpers/referrerSessionHelpers';
import { redirectToURLWithoutModalState } from '../../util/window';
import { ModalParams } from '../../util/urlHelpers';
import { useShopCss } from '../../hooks/useShopCss';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import { useRouteConfiguration } from '../../hooks/useRouteConfiguration';
import { initializeCardPaymentData } from '../../ducks/stripe.duck';
import { resetActiveModal } from '../../ducks/modal.duck';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { Feature } from '../../util/featureFlags';
import { TypographyFormat, TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import css from './ShoppingBag.module.css';

interface HeaderBarProps {
  children: ReactNode;
}

const HeaderBar: FC<HeaderBarProps> = (props: HeaderBarProps) => {
  const { children } = props;
  const shopCss = useShopCss();
  const bagTitle = shopCss?.addToBagButton?.bagText
    ? startCase(shopCss?.addToBagButton?.bagText)
    : 'Bag';

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      px={3}
      className={css.headerBar}
    >
      <div className={css.headerBox} />

      <TypographyWrapper typographyOverrides={{ style: { textAlign: 'center' } }} variant="h2">
        {bagTitle}
      </TypographyWrapper>

      {children}
    </Box>
  );
};

const EmptyBag: FC = () => {
  const dispatch = useDispatch();
  const { shopName } = useShopConfig();
  const shopCss = useShopCss();

  const emptyText = `Your ${shopCss?.addToBagButton?.bagText || 'bag'} is empty.`;
  const emptyButton = (
    <NamedLink
      name="SearchPage"
      to={{ search: '' }}
      className={css.shopButton}
      onClick={() => dispatch(resetActiveModal())}
      style={{ textDecoration: 'none' }}
    >
      <Button>Shop {shopName}</Button>
    </NamedLink>
  );

  return <Empty text={emptyText} button={emptyButton} />;
};

interface EditButtonProps {
  isEditing: boolean;
  onClick: () => void;
}

const EditButton: FC<EditButtonProps> = (props: EditButtonProps) => {
  const { isEditing, onClick } = props;
  const text = isEditing ? 'Done' : 'Edit';

  return (
    <InlineTextButton className={classNames(css.headerBox, css.editButton)} onClick={onClick}>
      <TypographyWrapper variant="body1">{text}</TypographyWrapper>
    </InlineTextButton>
  );
};

const OtherShopsListings: FC = () => {
  const { otherShopsListingsCountAndShopInfo } = useSelector<any>(
    (state) => state.shoppingBag
  ) as ShoppingBagState;

  return (
    <>
      {Object.entries(otherShopsListingsCountAndShopInfo).map(([treetId, countAndShopInfo]) => {
        const shopCanonicalRootUrl = getShopsCanonicalRootUrl(countAndShopInfo.shopUrl, treetId);
        const rootUrl = shopCanonicalRootUrl.concat(`?referrer=${Referrer.TreetShop}`);

        return (
          <a href={rootUrl} key={treetId}>
            <Divider className={css.divider} />
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              px={3}
              py={2}
            >
              <TypographyWrapper variant="body1">
                <TypographyWrapper
                  variant="body1"
                  weight={TypographyWeight.Bold}
                  typographyOverrides={{ display: 'inline' }}
                >
                  {pluralize('Item', countAndShopInfo.count, true)}
                </TypographyWrapper>{' '}
                from {countAndShopInfo.shopName}
              </TypographyWrapper>
              <IconArrowRight />
            </Box>
          </a>
        );
      })}
    </>
  );
};

interface ShoppingBagProps {
  isOpen: boolean;
  className?: string;
}

const ShoppingBag: FC<ShoppingBagProps> = (props: ShoppingBagProps) => {
  const { isOpen, className } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const routes = useRouteConfiguration();
  const { css: brandCss } = useShopConfig();
  const bgcolor = brandCss?.backgroundColor || 'white';

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const isMultiSellerEnabled = useFeatureFlags(Feature.MultiSeller);

  useEffect(() => {
    if (!isOpen) {
      setIsEditing(false);
    }
  }, [isOpen]);

  const { fetchShoppingBagListingsStatus: fetchListingsStatus, shoppingBagResultIds } =
    useSelector<any>((state) => state.shoppingBag) as ShoppingBagState;

  const listings = useSelector<any>((state) =>
    getListingsByUuid(state, shoppingBagResultIds)
  ) as ListingWithAuthorAndImages[];

  const handleMobileShoppingBagClose = () => {
    dispatch(resetActiveModal());
    redirectToURLWithoutModalState(history, location, ModalParams.MobileShoppingBag);
  };

  const isLoading = fetchListingsStatus === RequestStatus.Pending;
  const isEmpty = !isLoading && listings.length === 0;

  let shoppingBagContent;
  if (isLoading) {
    shoppingBagContent = (
      <Box p="20px">
        <TypographyWrapper variant="body1">Loading...</TypographyWrapper>
      </Box>
    );
  } else if (isEmpty) {
    shoppingBagContent = (
      <Box className={css.empty}>
        <EmptyBag />
      </Box>
    );
  } else {
    shoppingBagContent = <ShoppingBagBundles isEditing={isEditing} listings={listings} />;
  }

  const classes = classNames(css.root, className);

  const handleCheckoutAll = () => {
    // Customize checkout page state with shopping bag listings
    const { setInitialValues: checkoutPageSetInitialValues } = findRouteByRouteName(
      'CheckoutPage',
      routes
    );

    dispatch(checkoutPageSetInitialValues(listings));
    handleMobileShoppingBagClose();

    // Clear previous Stripe errors from store if there is any
    dispatch(initializeCardPaymentData());

    // Redirect to CheckoutPage
    history.push(createResourceLocatorString('CheckoutPage', routes));
  };

  return (
    <div className={classes}>
      <Box bgcolor={bgcolor} className={css.header}>
        <HeaderBar>
          {isLoading || isEmpty ? (
            <div className={css.headerBox} />
          ) : (
            <EditButton isEditing={isEditing} onClick={() => setIsEditing((prev) => !prev)} />
          )}
        </HeaderBar>
        <Divider className={css.divider} />
      </Box>
      <Box bgcolor={bgcolor} className={css.content}>
        {shoppingBagContent}
      </Box>
      <OtherShopsListings />
      {!isEmpty && (
        <>
          {isMultiSellerEnabled && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              px={3}
              py={2}
              className={css.continueFooter}
              bgcolor={bgcolor}
            >
              <InlineTextButton type="button" onClick={handleMobileShoppingBagClose}>
                <TypographyWrapper variant="body1" format={TypographyFormat.Underlined}>
                  Keep Shopping
                </TypographyWrapper>
              </InlineTextButton>
              <Box>
                <Button onClick={handleCheckoutAll}>Checkout All</Button>
              </Box>
            </Box>
          )}
          {!isMultiSellerEnabled && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              p={3}
              className={css.continueFooter}
              bgcolor={bgcolor}
            >
              <InlineTextButton type="button" onClick={handleMobileShoppingBagClose}>
                <Box display="flex" alignItems="center">
                  Keep Shopping
                  <IconArrowRight />
                </Box>
              </InlineTextButton>
            </Box>
          )}
        </>
      )}
    </div>
  );
};

export default ShoppingBag;
