import {
  Box,
  Card,
  CardActionArea,
  DialogActions,
  DialogContent,
  Grid,
  withStyles,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import classNames from 'classnames';
import PropTypes, { shape } from 'prop-types';
import React from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import {
  Button,
  DialogWithCloseButton,
  ExternalLink,
  Logo,
  NamedLink,
  TypographyWrapper,
} from '..';
import { useShopConfig } from '../../hooks/shopConfig';
import { PayoutOptions } from '../../util/constants';
import {
  getBankAccountLast4Digits,
  getStripeAccountData,
  hasFutureRequirementsMissing,
  hasRequirementsMissing,
} from '../../util/stripe';
import { required } from '../../util/validators';
import css from './PayoutMethodModal.module.css';
import { isGiftCardOption } from '../../shopConfig/shops/default';
import { TypographyFormat, TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import { useYotpo } from '../../hooks/useYotpo';
import { defaultTreetStyles } from '../../shopConfig/config';

const styles = {
  card: {
    height: '100%',
  },
  cardActionArea: {
    height: '100%',
    padding: '20px',
  },
  cardActionAreaSelected: {
    border: `4px solid ${defaultTreetStyles.gray80}`,
    'box-shadow': '0px 4px 16px 0px rgba(0, 0, 0, 0.30)',
    'transition-property': 'border',
    'transition-duration': '0.2s',
  },
  cardActionAreaDisabled: {
    color: 'lightgray',
  },
  box: {
    height: '100%',
  },
};

/**
 *
 * @param {*} input The input that will change when a payoutoption is selected
 * @param {*} payoutOption PayoutOptions type
 * @param {*} classes styling
 * @param {*} titleEl string or element to dislay in the title of the card
 * @param {*} descriptionEl string or element to display in the description section of the card
 * @param {*} logoEl logo to show on the bottom of the card
 * @param {*} disabled disable selection of the payout option
 * @returns
 */
function renderPayoutOptionCard(
  input,
  payoutOption,
  classes,
  titleEl,
  descriptionEl,
  logoEl,
  disabled = false
) {
  const { creditPayoutOption, storeCreditName } = useShopConfig();
  const { doesBrandUseLoyaltyPoints } = useYotpo();
  const isPayoutGiftCard = isGiftCardOption(creditPayoutOption);

  return (
    <Grid item xs={12} sm={6}>
      <Card variant="outlined" className={classes.card}>
        <CardActionArea
          className={classNames(classes.cardActionArea, {
            [classes.cardActionAreaSelected]: input.value === payoutOption,
            [classes.cardActionAreaDisabled]: disabled,
          })}
          onClick={() => input.onChange(payoutOption)}
          disabled={disabled}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            alignContent="center"
            justifyContent="space-between"
            className={classes.box}
          >
            <TypographyWrapper
              variant="body1"
              weight={TypographyWeight.Bold}
              typographyOverrides={{ style: { margin: '4px 0' } }}
            >
              {payoutOption === PayoutOptions.Credit ? 'Brand Credit' : 'Cash'}
            </TypographyWrapper>
            <TypographyWrapper variant="h2" typographyOverrides={{ style: { margin: '4px 0' } }}>
              {titleEl}
            </TypographyWrapper>
            <TypographyWrapper
              variant="body2"
              typographyOverrides={{ style: { margin: '4px 0', textAlign: 'center' } }}
            >
              {descriptionEl}
            </TypographyWrapper>
            {logoEl}
            {payoutOption === PayoutOptions.Credit && !doesBrandUseLoyaltyPoints && (
              <TypographyWrapper
                variant="body2"
                typographyOverrides={{ style: { fontSize: '12px' } }}
              >
                {`*Issued via ${
                  isPayoutGiftCard
                    ? storeCreditName || 'digital gift card'
                    : 'discount code. Limited to one use'
                }.`}
              </TypographyWrapper>
            )}
          </Box>
        </CardActionArea>
      </Card>
    </Grid>
  );
}

const LoyaltyPointsPayoutOptionCard = (props) => {
  const { input, classes, credit, creditAmount } = props;
  const { shopName } = useShopConfig();
  const { getPointsFromPrice } = useYotpo();
  return renderPayoutOptionCard(
    input,
    PayoutOptions.Credit,
    classes,
    <>
      <TypographyWrapper variant="h2" typographyOverrides={{ display: 'inline' }}>
        {getPointsFromPrice(creditAmount)} Points{' '}
      </TypographyWrapper>
      <TypographyWrapper
        variant="h2"
        typographyOverrides={{ display: 'inline' }}
        weight={TypographyWeight.Bold}
      >
        Worth {credit}*
      </TypographyWrapper>
    </>,
    <TypographyWrapper variant="body1" typographyOverrides={{ align: 'center' }}>
      Get {getPointsFromPrice(creditAmount)} points (worth {credit}) to use on your next purchase at{' '}
      {shopName}
    </TypographyWrapper>,
    <Logo className={css.logo} type="original" />
  );
};

LoyaltyPointsPayoutOptionCard.propTypes = {
  input: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  credit: PropTypes.string.isRequired,
  creditAmount: PropTypes.number.isRequired,
};

const BrandCreditPayoutOptionCard = (props) => {
  const { input, classes, credit, creditInBrandShopifyCurrency } = props;
  const { shopName, mainSite } = useShopConfig();
  const shopNameHyperlinked = (
    <ExternalLink href={mainSite}>
      <TypographyWrapper
        component="span"
        variant="body2"
        typographyOverrides={{ display: 'inline' }}
        format={TypographyFormat.Underlined}
      >
        {shopName}
      </TypographyWrapper>
    </ExternalLink>
  );
  return renderPayoutOptionCard(
    input,
    PayoutOptions.Credit,
    classes,
    `Credit Value (${creditInBrandShopifyCurrency || credit})`,
    <>
      Get{' '}
      <TypographyWrapper
        variant="body2"
        component="span"
        weight={TypographyWeight.Bold}
        typographyOverrides={{ display: 'inline' }}
      >
        {credit}
      </TypographyWrapper>
      {creditInBrandShopifyCurrency ? ', issued as '.concat(creditInBrandShopifyCurrency, ',') : ''}{' '}
      off your next purchase on {shopNameHyperlinked} (Instant)*
    </>,
    <Logo className={css.logo} type="original" />
  );
};

BrandCreditPayoutOptionCard.propTypes = {
  input: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  credit: PropTypes.string.isRequired,
  creditInBrandShopifyCurrency: PropTypes.string.isRequired,
};

const CashPayoutOptionCard = (props) => {
  const { input, classes, cash } = props;

  const stripeAccount = useSelector((state) => state.stripeConnectAccount.stripeAccount);

  const stripeAccountData = getStripeAccountData(stripeAccount);
  const requirementsMissing = hasRequirementsMissing(stripeAccount);
  const futureRequirementsMissing = hasFutureRequirementsMissing(stripeAccount);
  const last4 = getBankAccountLast4Digits(stripeAccountData);

  const isStripePayoutDisabled = !last4 || requirementsMissing;
  const stripeAccountMissingError = (
    <>
      You must connect your bank account{' '}
      <NamedLink className={css.payoutLink} name="StripePayoutPage" target="_blank">
        <TypographyWrapper
          variant="body1"
          format={TypographyFormat.Underlined}
          typographyOverrides={{ display: 'inline' }}
        >
          here
        </TypographyWrapper>
      </NamedLink>{' '}
      first before choosing this option.
    </>
  );

  const stripeAccountNotVerifiedError = (
    <>
      You must verify your Stripe account{' '}
      <NamedLink className={css.payoutLink} name="StripePayoutPage" target="_blank">
        <TypographyWrapper
          variant="body1"
          format={TypographyFormat.Underlined}
          typographyOverrides={{ display: 'inline' }}
        >
          here
        </TypographyWrapper>
      </NamedLink>{' '}
      first before choosing this option.
    </>
  );

  const stripeAccountFutureRequirementsMissingWarning = (
    <>
      Note: We need additional information for your Stripe account. Please update your account{' '}
      <NamedLink className={css.payoutLink} name="StripePayoutPage" target="_blank">
        <TypographyWrapper
          variant="body1"
          format={TypographyFormat.Underlined}
          typographyOverrides={{ display: 'inline' }}
        >
          here
        </TypographyWrapper>
      </NamedLink>{' '}
      to ensure you get paid.
    </>
  );

  return renderPayoutOptionCard(
    input,
    PayoutOptions.Cash,
    classes,
    `Direct Deposit (${cash})`,
    <>
      Transfer{' '}
      <TypographyWrapper
        variant="body2"
        component="span"
        weight={TypographyWeight.Bold}
        typographyOverrides={{ display: 'inline' }}
      >
        {cash}
      </TypographyWrapper>{' '}
      to your bank account {last4 || ''} (Up to 7 days)
      {isStripePayoutDisabled && (
        <h4 className={css.noStripeAccountWarning}>
          <ErrorIcon
            style={{
              color: '#ff4c4c',
              fontSize: '15px',
            }}
          />{' '}
          {!last4 && stripeAccountMissingError}
          {last4 && requirementsMissing && stripeAccountNotVerifiedError}
        </h4>
      )}
      {!isStripePayoutDisabled && futureRequirementsMissing && (
        <h4 className={css.noStripeAccountWarning}>
          {stripeAccountFutureRequirementsMissingWarning}
        </h4>
      )}
    </>,
    <img
      className={classNames(css.logo, isStripePayoutDisabled && css.logoDisabled)}
      src="/static/logos/stripe.png"
      alt="stripe-logo"
    />,
    isStripePayoutDisabled
  );
};

CashPayoutOptionCard.propTypes = {
  input: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  cash: PropTypes.string.isRequired,
};

const LoyaltyPointsDisclaimer = (props) => {
  const { credit, creditAmount } = props;

  const { shopName } = useShopConfig();
  const { getPointsFromPrice, linkToBrandLoyaltyPointsPage } = useYotpo();
  return (
    <Box py={2}>
      <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
        *Brand credit{' '}
      </TypographyWrapper>
      <TypographyWrapper
        variant="body1"
        typographyOverrides={{ display: 'inline' }}
        weight={TypographyWeight.Bold}
      >
        will be in the form of {shopName} Points.{' '}
      </TypographyWrapper>
      <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
        You will receive {getPointsFromPrice(creditAmount)} {shopName} Points, which is worth{' '}
        {credit} and can be used across multiple purchases.{' '}
      </TypographyWrapper>
      <ExternalLink href={linkToBrandLoyaltyPointsPage}>
        <TypographyWrapper
          variant="body1"
          typographyOverrides={{ display: 'inline' }}
          format={TypographyFormat.Underlined}
        >
          Learn more about {shopName} Points.
        </TypographyWrapper>
      </ExternalLink>
    </Box>
  );
};

LoyaltyPointsDisclaimer.propTypes = {
  credit: PropTypes.string.isRequired,
  creditAmount: PropTypes.number.isRequired,
};

export const PayoutMethodModalComponent = (props) => {
  const { open, onClose, onSubmit, inProgress, classes, payoutValues } = props;
  const { cash, credit, creditAmount, creditInBrandShopifyCurrency } = payoutValues;

  const { doesBrandUseLoyaltyPoints } = useYotpo();

  return (
    <FinalForm
      onSubmit={onSubmit}
      render={(fieldRenderProps) => {
        const { handleSubmit, invalid, submitting } = fieldRenderProps;
        return (
          <div>
            <form id="payoutMethodModal" onSubmit={handleSubmit}>
              <DialogWithCloseButton
                fullWidth
                maxWidth="md"
                open={open}
                onClose={onClose}
                titleText="Choose Payout Method"
              >
                <DialogContent dividers>
                  <Box pb={1}>
                    <TypographyWrapper variant="body1" weight={TypographyWeight.Bold}>
                      Please choose how you would like to be paid out.
                    </TypographyWrapper>
                  </Box>
                  <Field name="payoutOption" validate={required(`Please choose an option`)}>
                    {({ input }) => (
                      <Grid container spacing={3}>
                        {credit && doesBrandUseLoyaltyPoints && (
                          <LoyaltyPointsPayoutOptionCard
                            input={input}
                            classes={classes}
                            credit={credit}
                            creditAmount={creditAmount}
                          />
                        )}
                        {credit && !doesBrandUseLoyaltyPoints && (
                          <BrandCreditPayoutOptionCard
                            input={input}
                            classes={classes}
                            credit={credit}
                            creditInBrandShopifyCurrency={creditInBrandShopifyCurrency}
                          />
                        )}

                        {cash && (
                          <CashPayoutOptionCard input={input} classes={classes} cash={cash} />
                        )}
                      </Grid>
                    )}
                  </Field>
                  <Box py={1}>
                    {doesBrandUseLoyaltyPoints && (
                      <LoyaltyPointsDisclaimer credit={credit} creditAmount={creditAmount} />
                    )}
                    <TypographyWrapper variant="body1" weight={TypographyWeight.Bold}>
                      NOTE: PAYOUT SELECTION IS FINAL AND CANNOT BE CHANGED FOR THIS SALE.
                    </TypographyWrapper>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button
                    type="submit"
                    className={css.actionButton}
                    disabled={invalid || submitting}
                    inProgress={submitting || inProgress}
                    form="payoutMethodModal"
                  >
                    Get Paid
                  </Button>
                </DialogActions>
              </DialogWithCloseButton>
            </form>
          </div>
        );
      }}
    />
  );
};

PayoutMethodModalComponent.defaultProps = {
  inProgress: false,
  classes: {},
};

PayoutMethodModalComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  inProgress: PropTypes.bool,
  payoutValues: shape({
    cash: PropTypes.string,
    credit: PropTypes.string,
  }).isRequired,
  classes: PropTypes.object,
};

export default withStyles(styles)(PayoutMethodModalComponent);
