import React from 'react';
import { KeywordFilter, SelectMultipleFilter, SelectSingleFilter } from '../../components';
import FilterCheckbox from '../../components/FilterCheckbox/FilterCheckbox';
import SortByRadio from '../../components/SortByRadio/SortByRadio';
import { SearchFilterType } from '../../types/filters/filters';
import { FrenzyApiModes } from '../../types/frenzy/query';

/**
 * FilterComponent is used to map configured filter types
 * to actual filter components
 */
const FilterComponent = (props) => {
  const {
    idPrefix,
    filterConfig,
    urlQueryParams,
    getInitialValues,
    getHandleChangedValueFn,
    onFilterChange,
    ...rest
  } = props;

  const { id, type, queryParamNames, label, config } = filterConfig;
  const { liveEdit, showAsPopup } = rest;

  const useHistoryPush = liveEdit || showAsPopup;
  const prefix = idPrefix || 'SearchPage';
  const componentId = `${prefix}.${id.toLowerCase()}`;
  const name = id.replace(/\s+/g, '-').toLowerCase();

  switch (type) {
    case SearchFilterType.SelectSingleFilter: {
      return (
        <SelectSingleFilter
          id={componentId}
          label={label}
          queryParamNames={queryParamNames}
          initialValues={getInitialValues(queryParamNames)}
          onSelect={getHandleChangedValueFn(useHistoryPush, FrenzyApiModes.FilterChange)}
          {...config}
          {...rest}
        />
      );
    }
    case SearchFilterType.SelectMultipleFilter: {
      return (
        <SelectMultipleFilter
          id={componentId}
          label={label}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={getInitialValues(queryParamNames)}
          onSubmit={getHandleChangedValueFn(useHistoryPush, FrenzyApiModes.FilterChange)}
          {...config}
          {...rest}
        />
      );
    }
    case SearchFilterType.KeywordFilter:
      return (
        <KeywordFilter
          id={componentId}
          label={label}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={getInitialValues(queryParamNames)}
          onSubmit={getHandleChangedValueFn(useHistoryPush, FrenzyApiModes.RawQuery)}
          {...config}
          {...rest}
        />
      );

    // Treet V2 Filters below
    case SearchFilterType.Checkbox:
      return (
        <FilterCheckbox
          label={label}
          filters={[filterConfig]}
          getInitialValues={getInitialValues}
          onSubmit={onFilterChange}
          {...rest}
        />
      );
    case SearchFilterType.Sort:
      return (
        <SortByRadio
          urlQueryParams={urlQueryParams}
          sortConfig={filterConfig}
          onSubmit={onFilterChange}
          showAsPopup
          {...rest}
        />
      );
    default:
      return null;
  }
};

export default FilterComponent;
