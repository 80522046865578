import React, { FC } from 'react';
import { Box, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { Button } from '..';
import DialogWithCloseButton from '../DialogWithCloseButton/DialogWithCloseButton';
import { ButtonVariant } from '../Button/Button';
import TypographyWrapper, { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import { PHOTO_TIPS_TO_LABEL, PhotoTips } from '../../util/listings/listing';

interface PhotoTipsModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  photoTipOptions: any;
}

const PhotoTipsModal: FC<PhotoTipsModalProps> = (props) => {
  const { isOpen, onClose, title, photoTipOptions } = props;

  return (
    <DialogWithCloseButton
      scroll="paper"
      open={isOpen}
      onClose={onClose}
      titleText={title}
      fullWidth
    >
      <DialogContent dividers>
        <DialogContentText>
          {Object.entries(photoTipOptions).map(([tip, description]) => (
            <Box key={tip} mb={2}>
              <TypographyWrapper variant="body1" weight={TypographyWeight.Bold}>
                {PHOTO_TIPS_TO_LABEL[tip as PhotoTips]}
              </TypographyWrapper>
              <TypographyWrapper variant="body1">{description as string}</TypographyWrapper>
            </Box>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant={ButtonVariant.Primary}>
          Got It
        </Button>
      </DialogActions>
    </DialogWithCloseButton>
  );
};

export default PhotoTipsModal;
