import { Currency } from '../types/apollo/generated/types.generated';

const getListingItemAttributes = (listing) => [
  {
    item_id: listing?.id?.uuid,
    item_name: listing?.attributes?.title,
    item_brand: listing?.attributes?.publicData?.shopName,
    item_category: listing?.attributes?.publicData?.category,
    // Note: this will not correctly get the size for listings that have a
    // special sizeVariantOptionName
    item_variant: listing?.attributes?.publicData?.size,
    price: listing?.attributes?.price?.amount / 100,
    currency: listing?.attributes?.price?.currency || Currency.Usd,
    quantity: 1,
  },
];

export const setUserId = (userId) => {
  if (window.gtag) {
    window.gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS_4_ID, {
      user_id: userId,
    });
  }
};

export const logSelectItem = (listing, referrerLocation) => {
  if (window.gtag && listing) {
    window.gtag('event', 'select_item', {
      items: {
        ...getListingItemAttributes(listing),
        item_list_name: referrerLocation,
      },
    });
  }
};

// Currently this is just the button that takes them to the checkout page. they may be redirected here
export const logAddToCart = (listing) => {
  if (window.gtag && listing) {
    window.gtag('event', 'add_to_cart', {
      items: getListingItemAttributes(listing),
      value: listing.attributes?.price?.amount / 100,
    });
  }
  if (window.fbq && listing) {
    window.fbq('track', 'AddToCart', {
      items: getListingItemAttributes(listing),
      value: listing.attributes?.price?.amount / 100,
    });
  }
};

export const logBeginCheckout = (listings) => {
  if (window.gtag && listings) {
    listings.map((listing) =>
      window.gtag('event', 'begin_checkout', {
        items: getListingItemAttributes(listing),
        value: listing?.attributes?.price?.amount / 100,
      })
    );
  }
  if (window.fbq && Array.isArray(listings)) {
    listings.map((listing) =>
      window.fbq('track', 'InitiateCheckout', {
        items: getListingItemAttributes(listing),
        value: listing?.attributes?.price?.amount / 100,
        currency: listing?.attributes?.price?.currency || Currency.Usd,
      })
    );
  }
};

export const logPurchase = (listing) => {
  if (window.gtag && listing) {
    window.gtag('event', 'purchase', {
      items: getListingItemAttributes(listing),
      value: listing.attributes?.price?.amount / 100,
    });
  }
  if (window.fbq && listing) {
    window.fbq('track', 'Purchase', {
      items: getListingItemAttributes(listing),
      value: listing?.attributes?.price?.amount / 100,
      content_ids: [listing?.id?.uuid],
      content_type: 'product',
      currency: listing?.attributes?.price?.currency || Currency.Usd,
    });
  }
};

export const logFavoriteItem = (listing) => {
  if (window.gtag && listing) {
    window.gtag('event', 'favorite_item', {
      items: getListingItemAttributes(listing),
    });
  }
  if (window.fbq && listing) {
    window.fbq('track', 'AddToWishlist', {
      items: getListingItemAttributes(listing),
    });
  }
};

export const logUnfavoriteItem = (listing) => {
  if (window.gtag && listing) {
    window.gtag('event', 'unfavorite_item', {
      items: getListingItemAttributes(listing),
    });
  }
};

export const logSignUp = () => {
  if (window.gtag) {
    window.gtag('event', 'sign_up');
  }
  if (window.fbq) {
    window.fbq('track', 'CompleteRegistration');
  }
};

export const logLogin = () => {
  if (window.gtag) {
    window.gtag('event', 'log_in');
  }
};

export const logDiscardDraft = () => {
  if (window.gtag) {
    window.gtag('event', 'discard_draft');
  }
};

export const logBeginGoogleLogin = () => {
  if (window.gtag) {
    window.gtag('event', 'begin_google_login');
  }
};

export const logBeginGoogleSignup = () => {
  if (window.gtag) {
    window.gtag('event', 'begin_google_signup');
  }
};
