import React from 'react';
import { bool, error, func, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import { Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { injectIntl } from '../../util/reactIntl';
import {
  Button,
  Error,
  Form,
  FormattedMessage,
  ModalParagraph,
  ModalTitle,
  NamedLink,
  TypographyWrapper,
} from '../../components';
import { verify } from '../../ducks/EmailVerification.duck';
import { propTypes } from '../../util/types';
import { TypographyWeight } from '../../components/TypographyWrapper/TypographyWrapper';
import css from './EmailVerificationForm.module.css';

const VerifyEmail = (props) => {
  const { currentUser, inProgress, handleSubmit, verificationError } = props;

  const { email, pendingEmail } = currentUser.attributes;
  const emailToVerify = (
    <TypographyWrapper variant="body1" weight={TypographyWeight.Bold}>
      {pendingEmail || email}
    </TypographyWrapper>
  );

  const errorMessage = (
    <Box mt={3}>
      <Error>
        <FormattedMessage id="EmailVerificationForm.verificationFailed" />
      </Error>
    </Box>
  );

  return (
    <div className={css.root}>
      <div>
        <ModalTitle>
          <FormattedMessage id="EmailVerificationForm.verifyEmailAddress" />
        </ModalTitle>
        <ModalParagraph>
          <FormattedMessage
            id="EmailVerificationForm.finishAccountSetup"
            values={{ email: emailToVerify }}
          />
        </ModalParagraph>
        {verificationError ? errorMessage : null}
      </div>

      <Form onSubmit={handleSubmit}>
        <Field component="input" type="hidden" name="verificationToken" />

        <div className={css.bottomWrapper}>
          <Button type="submit" inProgress={inProgress} disabled={inProgress}>
            {inProgress ? (
              <FormattedMessage id="EmailVerificationForm.verifying" />
            ) : (
              <FormattedMessage id="EmailVerificationForm.verify" />
            )}
          </Button>
        </div>
      </Form>
    </div>
  );
};

VerifyEmail.defaultProps = {
  inProgress: false,
  verificationError: null,
};

VerifyEmail.propTypes = {
  inProgress: bool,
  currentUser: propTypes.currentUser.isRequired,
  verificationError: error,
  handleSubmit: func.isRequired,
};

const AlreadyVerified = (props) => {
  const { name } = props;

  return (
    <div className={css.root}>
      <div>
        <ModalTitle>
          <FormattedMessage id="EmailVerificationForm.successTitle" values={{ name }} />
        </ModalTitle>
        <ModalParagraph>
          <FormattedMessage id="EmailVerificationForm.successText" />
        </ModalParagraph>
      </div>

      <div className={css.bottomWrapper}>
        <NamedLink name="LandingPage" style={{ textDecoration: 'none' }}>
          <Button>Continue to site</Button>
        </NamedLink>
      </div>
    </div>
  );
};

AlreadyVerified.defaultProps = {
  name: '',
};

AlreadyVerified.propTypes = {
  name: string,
};

const EmailVerificationFormComponent = (props) => {
  const dispatch = useDispatch();
  const handleSubmitVerification = ({ verificationToken }) => dispatch(verify(verificationToken));

  return (
    <FinalForm
      {...props}
      onSubmit={handleSubmitVerification}
      render={(formRenderProps) => {
        const { currentUser, inProgress, handleSubmit, verificationError } = formRenderProps;

        const { emailVerified, pendingEmail, profile } = currentUser.attributes;
        const name = profile.firstName;

        return emailVerified && !pendingEmail ? (
          <AlreadyVerified name={name} />
        ) : (
          <VerifyEmail
            currentUser={currentUser}
            inProgress={inProgress}
            handleSubmit={handleSubmit}
            verificationError={verificationError}
          />
        );
      }}
    />
  );
};

EmailVerificationFormComponent.defaultProps = {
  inProgress: false,
  verificationError: null,
};

EmailVerificationFormComponent.propTypes = {
  inProgress: bool,
  currentUser: propTypes.currentUser.isRequired,
  verificationError: error,
};

const EmailVerificationForm = compose(injectIntl)(EmailVerificationFormComponent);

export default EmailVerificationForm;
