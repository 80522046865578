import React from 'react';
import PropTypes, { element } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import * as validators from '../../util/validators';
import {
  Button,
  ExternalLink,
  FieldCheckbox,
  FieldTextInput,
  Form,
  FormattedMessage,
  NamedLink,
  TypographyWrapper,
} from '../../components';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { parse } from '../../util/urlHelpers';
import css from './SignupForm.module.css';
import { TypographyFormat } from '../../components/TypographyWrapper/TypographyWrapper';
import { useShopConfig } from '../../hooks/shopConfig';

const SignupFormComponent = (props) => {
  const location = useLocation();
  const { email: emailAddress } = parse(location.search);
  const initialValues = { email: emailAddress };

  const {
    copy: { brandPrivacyPolicyUrl },
    shopName,
  } = useShopConfig();
  const { isBrandDirectOnly } = useEnabledCustomerExperiences();

  return (
    <FinalForm
      {...props}
      initialValues={initialValues}
      render={(fieldRenderProps) => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          termsLink,
          privacyLink,
          authInfo,
          doesBrandUseLoyaltyPoints,
        } = fieldRenderProps;

        // email
        const emailLabel = intl.formatMessage({
          id: 'SignupForm.emailLabel',
        });
        const emailPlaceholder = intl.formatMessage({
          id: 'SignupForm.emailPlaceholder',
        });
        const emailRequiredMessage = intl.formatMessage({
          id: 'SignupForm.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        // password
        const passwordLabel = intl.formatMessage({
          id: 'SignupForm.passwordLabel',
        });
        const passwordPlaceholder = intl.formatMessage({
          id: 'SignupForm.passwordPlaceholder',
        });
        const passwordRequiredMessage = intl.formatMessage({
          id: 'SignupForm.passwordRequired',
        });
        const passwordMinLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooShort',
          },
          {
            minLength: validators.PASSWORD_MIN_LENGTH,
          }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooLong',
          },
          {
            maxLength: validators.PASSWORD_MAX_LENGTH,
          }
        );
        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
        const passwordValidators = validators.composeValidators(
          passwordRequired,
          passwordMinLength,
          passwordMaxLength
        );

        // firstName
        const firstNameLabel = intl.formatMessage({
          id: 'SignupForm.firstNameLabel',
        });
        const firstNamePlaceholder = intl.formatMessage({
          id: 'SignupForm.firstNamePlaceholder',
        });
        const firstNameRequiredMessage = intl.formatMessage({
          id: 'SignupForm.firstNameRequired',
        });
        const firstNameRequired = validators.required(firstNameRequiredMessage);

        // lastName
        const lastNameLabel = intl.formatMessage({
          id: 'SignupForm.lastNameLabel',
        });
        const lastNamePlaceholder = intl.formatMessage({
          id: 'SignupForm.lastNamePlaceholder',
        });
        const lastNameRequiredMessage = intl.formatMessage({
          id: 'SignupForm.lastNameRequired',
        });
        const lastNameRequired = validators.required(lastNameRequiredMessage);

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress;

        // In the case that we have received data from the Google auth cookie,
        // extract these values to pre-populate the form values.
        const { email, firstName, lastName } = authInfo || {};

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div>
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={emailLabel}
                placeholder={emailPlaceholder}
                initialValue={email}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              {!isBrandDirectOnly && (
                <h5>
                  <TypographyWrapper
                    variant="body2"
                    typographyOverrides={{ style: { marginTop: '12px' } }}
                  >
                    {doesBrandUseLoyaltyPoints
                      ? 'If you have a rewards account, use the same email here for easy points transfer!'
                      : 'We suggest using the email you prefer to shop with to make the selling process easier!'}
                  </TypographyWrapper>
                </h5>
              )}
              <div className={css.name}>
                <FieldTextInput
                  className={css.firstNameRoot}
                  type="text"
                  id={formId ? `${formId}.fname` : 'fname'}
                  name="fname"
                  autoComplete="given-name"
                  label={firstNameLabel}
                  placeholder={firstNamePlaceholder}
                  initialValue={firstName}
                  validate={firstNameRequired}
                />
                <FieldTextInput
                  className={css.lastNameRoot}
                  type="text"
                  id={formId ? `${formId}.lname` : 'lname'}
                  name="lname"
                  autoComplete="family-name"
                  label={lastNameLabel}
                  placeholder={lastNamePlaceholder}
                  initialValue={lastName}
                  validate={lastNameRequired}
                />
              </div>
              <FieldTextInput
                className={css.password}
                type="password"
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="new-password"
                label={passwordLabel}
                placeholder={passwordPlaceholder}
                validate={passwordValidators}
              />
            </div>

            <div className={css.bottomWrapper}>
              <p className={css.bottomWrapperText}>
                <FieldCheckbox
                  id="termsAgreement"
                  name="termsAgreement"
                  wrapperClassName={css.termsCheckboxWrapper}
                  label={
                    <span className={css.termsText}>
                      <TypographyWrapper
                        variant="body1"
                        typographyOverrides={{ display: 'inline' }}
                      >
                        I have read and agree to the {termsLink} and {privacyLink}.
                        {brandPrivacyPolicyUrl && (
                          <>
                            {' '}
                            By checking this box, I consent to Treet passing my details to{' '}
                            {shopName} so that {shopName} can email me information about their
                            products, services, news, and offers. Please see{' '}
                            <NamedLink name="PrivacyPolicyPage" target="_blank">
                              <TypographyWrapper
                                variant="body1"
                                format={TypographyFormat.Underlined}
                                typographyOverrides={{ display: 'inline' }}
                              >
                                Treet’s privacy policy
                              </TypographyWrapper>
                            </NamedLink>{' '}
                            and{' '}
                            <ExternalLink href={brandPrivacyPolicyUrl}>
                              <TypographyWrapper
                                variant="body1"
                                format={TypographyFormat.Underlined}
                                typographyOverrides={{ display: 'inline' }}
                              >
                                {shopName}’s privacy policy
                              </TypographyWrapper>
                            </ExternalLink>{' '}
                            for more information about the use of your personal data for marketing
                            by {shopName} and on how to withdraw your consent to marketing.
                          </>
                        )}
                      </TypographyWrapper>
                    </span>
                  }
                  value="true"
                  validate={validators.requiredFieldArrayCheckbox('Required')}
                  textClassName={css.termsText}
                />
              </p>
              <Button type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="SignupForm.signUp" />
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
};

SignupFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  authInfo: null,
  isConfirm: false,
  idp: null,
  doesBrandUseLoyaltyPoints: false,
};

const { bool, func, string, shape } = PropTypes;

SignupFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  handleSubmit: func.isRequired,
  inProgress: bool.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
  termsLink: element.isRequired,
  privacyLink: element.isRequired,
  authInfo: shape({
    email: string,
    firstName: string,
    lastName: string,
  }),
  isConfirm: bool,
  idp: string,
  doesBrandUseLoyaltyPoints: bool,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
