import { getConditionsFilterConfig } from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { User } from '../../types/sharetribe/user';

const mainstreetexchangeapparelSizes = [
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  '1X',
  '2X',
  '3X',
  '4X',
  '0',
  '2',
  '4',
  '6',
  '8',
  '10',
  '12',
  '14',
  '16',
  '18',
  '20',
];

const handleMainstreetexchangeapparelCustomCreditDescription = (data: {
  bundle: {
    shipFromAddress: { state: string };
  };
  recipient: User;
}) => {
  const { email } = data.recipient?.attributes || {};

  return `Email: ${email}`;
};

export const mainstreetexchangeapparelShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: mainstreetexchangeapparelSizes }),
  ],
  additionalPayoutSettings: {
    buildCustomCreditDescription: handleMainstreetexchangeapparelCustomCreditDescription,
  },
};
